"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _auction = require("@/api/auction");
var _index = require("@/utils/index");
var _user = require("@/api/user");
var _webSocket = _interopRequireDefault(require("@/utils/webSocket"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      venue_id: '',
      albumlist: [],
      albumId: '',
      listLoading: false,
      list: [],
      sdata: {
        socketServe: _webSocket.default.Instance
      },
      showdetail: null,
      uid: '',
      queryParams: {
        page: 1,
        limit: 20,
        album_id: ''
      },
      total: 0,
      // 委托出价
      proxyData: {
        uid: '',
        price: null,
        auctionId: '',
        show: false,
        tuluhao: '',
        title: '',
        btnLoading: false,
        anonymous: 1,
        bid_type: 1
      },
      searchLoading: false,
      userList: [],
      showBidRecord: false,
      showLoading: false,
      showBidRecordData: {
        data: {
          product: {}
        },
        list: [],
        idsList: []
      },
      albumData: {},
      allCzArray: [],
      proxyBidData: {
        show: false,
        num: 0,
        uv: 0,
        list: [],
        page: 1,
        limit: 20,
        total: 0
      },
      albumVisitData: {
        show: false,
        uv: 0,
        num: 0,
        list: []
      },
      auctionVisitData: {
        show: false,
        store_name: '',
        lot: '',
        list: []
      },
      albumRelationData: {
        show: false,
        uv: 0,
        num: 0,
        list: []
      },
      otherTotal: 0,
      query: {
        page: 1,
        limit: 20
      },
      showNumberList: [],
      changeUser: {},
      searchQuery: {},
      isNo: true,
      liveCount: 0
    };
  },
  created: function created() {
    this.liveCount = Number(this.$route.query.liveCount);
  },
  mounted: function mounted() {
    var _this = this;
    this.venue_id = this.$route.query.vid || '';
    this.albumId = this.$route.query.zcid || '';
    if (this.venue_id) {
      (0, _auction.albumOptionList)({
        page: 1,
        limit: 100,
        venue_id: this.venue_id
      }).then(function (res) {
        console.log(res.data.list);
        _this.albumlist = res.data.list;
        if (!_this.albumId) {
          _this.albumId = res.data.list[0].album_id;
          _this.isNo = res.data.list[0].album_status != 3;
        }
        _this.loadAlbumDetail();
        _this.loadAuction();
      });
    }
    this.delayRequest();
    (0, _index.countDown)(99999, function () {
      if (_this.list && _this.list.length > 0) {
        for (var key in _this.list) {
          var m = _this.list[key];
          if (m.auction_status === 1 && m.end_time || m.auction_status === 2 && m.start_time) {
            var time = m.auction_status === 1 ? m.end_time : m.start_time;
            var newD = Date.parse(new Date()) / 1000;
            var goTime = time - newD;
            var day = parseInt(goTime / 3600 / 24);
            var hour = parseInt(Math.floor(goTime % (3600 * 24)) / 3600);
            var minite = Math.floor(goTime / 60 % 60);
            var second = Math.floor(goTime % 60);
            if (!day && !hour && !minite && !second) {
              if (m.auction_status === 1) {
                _this.list[key].auction_status = 5;
              } else if (m.auction_status === 2) {
                _this.list[key].auction_status = 1;
              }
            }
            if (goTime === 5) {
              _this.loadAuction(1, 1);
            }
            _this.list[key].time = {
              goTime: goTime,
              day: day,
              hour: hour,
              minite: minite,
              second: second
            };
          }
        }
      }
    }, function (setinter) {
      _this.inter = setinter;
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (this.inter) clearInterval(this.inter);
    if (this.albumTimeout) clearTimeout(this.albumTimeout);
  },
  methods: {
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.queryParams = Object.assign({
        album_id: this.queryParams.album_id,
        page: 1,
        limit: this.queryParams.limit
      }, e);
      this.loadAuction();
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    showAlbumRelationDataAction: function showAlbumRelationDataAction() {
      this.otherTotal = 0;
      this.albumRelationData.show = true;
      this.albumRelationData.uv = this.albumData.album_care_count.uv;
      this.albumRelationData.num = this.albumData.album_care_count.num;
      this.query = {
        page: 1,
        limit: 20,
        album_id: this.albumId
      };
      this.loadAlbumRelationData();
    },
    loadAlbumRelationData: function loadAlbumRelationData() {
      var _this2 = this;
      (0, _auction.albumRelationListApi)(this.query).then(function (res) {
        _this2.albumRelationData.list = res.data.list;
        _this2.otherTotal = res.data.count;
      }).catch(function () {});
    },
    showAuctionVisitDataAction: function showAuctionVisitDataAction(m) {
      this.otherTotal = 0;
      this.auctionVisitData.show = true;
      this.auctionVisitData.lot = m.lot;
      this.auctionVisitData.store_name = m.product.store_name;
      this.query = {
        page: 1,
        limit: 20,
        auction_id: m.product_id
      };
      this.loadAuctionVisitData();
    },
    loadAuctionVisitData: function loadAuctionVisitData() {
      var _this3 = this;
      (0, _auction.auctionVisitListApi)(this.query).then(function (res) {
        _this3.auctionVisitData.list = res.data.list;
        _this3.otherTotal = res.data.count;
      }).catch(function () {});
    },
    showAlbumVisitDataAction: function showAlbumVisitDataAction() {
      this.otherTotal = 0;
      this.albumVisitData.show = true;
      this.albumVisitData.uv = this.albumData.click_num_uv;
      this.albumVisitData.num = this.albumData.click_num_uv_product;
      this.query = {
        page: 1,
        limit: 20,
        album_id: this.albumId
      };
      this.loadAlbumVisitData();
    },
    loadAlbumVisitData: function loadAlbumVisitData() {
      var _this4 = this;
      (0, _auction.albumVisitListApi)(this.query).then(function (res) {
        _this4.albumVisitData.list = res.data.list;
        _this4.otherTotal = res.data.count;
      }).catch(function () {});
    },
    pageOtherChange: function pageOtherChange(page) {
      this.query.page = page;
      if (this.albumVisitData.show) {
        this.loadAlbumVisitData();
      } else if (this.auctionVisitData.show) {
        this.loadAuctionVisitData();
      } else if (this.albumRelationData.show) {
        this.loadAlbumRelationData();
      }
    },
    showProxyBidDataAction: function showProxyBidDataAction() {
      this.proxyBidData.show = true;
      this.proxyBidData.uv = this.albumData.proxy_bid_uv;
      this.proxyBidData.num = this.albumData.proxy_bid_num;
      // let model = {
      //   page: 1,
      //   limit: 20,
      //   album_id: this.albumId
      // };
      this.loadProxyBidData();
    },
    loadProxyBidData: function loadProxyBidData() {
      var _this5 = this;
      var model = {
        page: this.proxyBidData.page,
        limit: this.proxyBidData.limit,
        album_id: this.albumId
      };
      (0, _auction.proxyBidListApi)(model).then(function (res) {
        _this5.proxyBidData.list = res.data.list;
        _this5.proxyBidData.total = res.data.count;
      }).catch(function () {});
    },
    proxyPageChange: function proxyPageChange(val) {
      this.proxyBidData.page = val;
      this.loadProxyBidData();
    },
    delayRequest: function delayRequest() {
      var _this6 = this;
      this.albumTimeout = setTimeout(function () {
        _this6.loadAlbumDetail(1);
        _this6.loadAuction(1, 1);
        _this6.delayRequest();
      }, 10000);
    },
    loadAlbumDetail: function loadAlbumDetail(refresh) {
      var _this7 = this;
      (0, _auction.albumDetail)(this.albumId).then(function (res) {
        _this7.albumData = res.data;
        if (_this7.albumData.album_status === 2 && !refresh) {
          if (!_webSocket.default.Instance.ws) {
            _webSocket.default.Instance.connect();
            _this7.sdata.socketServe = _webSocket.default.Instance;
            _this7.sdata.socketServe.registerCallBack('getMessage', function (data) {
              _this7.sockedMes(data);
            });
            _this7.sdata.socketServe.registerCallBack('openSocket', function () {});
          } else if (_this7.client_id) {
            _this7.joinRoomAction();
          }
        }
        if (_this7.albumData.album_status !== 2) {
          if (_this7.albumTimeout) clearTimeout(_this7.albumTimeout);
        }
      });
    },
    loadAuction: function loadAuction(refresh, noLoading) {
      var _this8 = this;
      this.queryParams.album_id = this.albumId;
      if (!noLoading) {
        this.listLoading = true;
      }
      (0, _auction.statisticalAuctionListApi)(this.queryParams).then(function (res) {
        if (refresh) {
          var auctionIds = res.data.list.map(function (m) {
            return m.product_id;
          });
          _this8.list.forEach(function (m, i) {
            var index = auctionIds.indexOf(m.product_id);
            if (index !== -1) {
              var item = res.data.list[index];
              _this8.list[i].online_num = item.online_num;
              _this8.list[i].bid_num_uv = item.bid_num_uv;
              _this8.list[i].bid_num = item.bid_num;
              _this8.list[i].click_uv = item.click_uv;
              _this8.list[i].now_price = item.now_price;
              _this8.list[i].start_time = item.start_time;
              _this8.list[i].end_time = item.end_time;
              _this8.list[i].auction_status = item.auction_status;
              _this8.list[i].user = item.user;
              if (item.number) {
                _this8.list[i].number = item.number;
              }
            }
          });
        } else {
          res.data.list.forEach(function (m) {
            m.time = {
              goTime: '',
              day: '',
              hour: '',
              minite: '',
              second: ''
            };
          });
          _this8.total = res.data.count;
          _this8.list = res.data.list;
        }
        if (!noLoading) {
          _this8.listLoading = false;
        }
      });
    },
    changeSelect: function changeSelect(val) {
      var albumRow = this.albumlist.find(function (item) {
        return item.album_id == val;
      });
      this.isNo = albumRow.album_status != 3;
      if (this.albumTimeout) clearTimeout(this.albumTimeout);
      this.loadAlbumDetail();
      this.loadAuction();
      this.delayRequest();
    },
    proxyBidAction: function proxyBidAction(m) {
      this.loadNumberList(m.product_id, m.venue_id);
      // 委托出价点击
      this.proxyData = {
        uid: '',
        price: null,
        auctionId: m.product_id,
        show: true,
        tuluhao: m.lot,
        title: m.product.store_name,
        btnLoading: false,
        anonymous: 1,
        bid_type: 1
      };
    },
    clearProxyBid: function clearProxyBid() {
      // 取消委托出价
      this.proxyData.show = false;
    },
    proxyBidConfirmAction: function proxyBidConfirmAction() {
      var _this9 = this;
      // 确认委托出价
      this.proxyData.btnLoading = true;
      var action = _auction.proxyBidConfirm;
      if (this.proxyData.bid_type === 1) {
        action = _auction.bidPrice;
      }
      action({
        auction_id: this.proxyData.auctionId,
        bid_uid: this.changeUser.uid || this.proxyData.uid,
        bid_price: this.proxyData.price,
        name_hidden: this.proxyData.anonymous
      }).then(function (res) {
        _this9.$message.success('设置成功');
        _this9.loadAuction(1);
        _this9.proxyData.btnLoading = false;
        _this9.clearProxyBid();
      }).catch(function (message) {
        _this9.proxyData.btnLoading = false;
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this10 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 20
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this10.userList = res.data.list;
      });
    },
    showRecordAction: function showRecordAction(product) {
      this.showBidRecordData = {
        data: product,
        list: []
      };
      this.showBidRecord = true;
      this.loadRecordAction();
    },
    loadRecordAction: function loadRecordAction() {
      var _this11 = this;
      this.showLoading = true;
      (0, _auction.auctionBidRecordApi)({
        auction_id: this.showBidRecordData.data.product_id
      }).then(function (res) {
        _this11.showBidRecordData.idsList = res.data.map(function (m) {
          return m.user ? m.user.uid : '';
        });
        _this11.showBidRecordData.list = res.data;
        if (_this11.isLock) {
          _this11.allCzArray = [];
          _this11.isLock = false;
        }
        _this11.showLoading = false;
      });
    },
    sockedMes: function sockedMes(d) {
      if (d.type === 'init') {
        // 初始化
        this.client_id = d.client_id;
        this.joinRoomAction();
      } else if (d.type === 'auction_bid' || d.type === 'auction_bid_proxy') {
        if (this.showBidRecord && this.showBidRecordData.data && this.showBidRecordData.data.product_id) {
          var record = d.type === 'auction_bid_proxy' ? d.data.bid_record_list[0] : d.data.bid_record;
          this.allCzArray.push(record);
          this.setData();
        }
      }
    },
    setData: function setData() {
      if (this.allCzArray[0] && !this.isLock) {
        this.isLock = true;
        var record = JSON.parse(JSON.stringify(this.allCzArray[0]));
        this.allCzArray.splice(0, 1);
        var index = this.showBidRecordData.idsList.indexOf(record.uid);
        if (index === -1) {
          this.loadRecordAction();
        } else {
          this.showBidRecordData.list[index].bid_record = [record].concat(this.showBidRecordData.list[index].bid_record);
          var item = JSON.parse(JSON.stringify(this.showBidRecordData.list[index]));
          this.showBidRecordData.list.splice(index, 1);
          this.showBidRecordData.list.splice(0, 0, item);
          this.showBidRecordData.idsList = this.showBidRecordData.list.map(function (m) {
            return m.user.uid;
          });
          this.isLock = false;
          this.setData();
        }
      }
    },
    joinRoomAction: function joinRoomAction() {
      (0, _auction.joinRoom)({
        album_id: this.albumId,
        client_id: this.client_id
      }).then(function (res) {}).catch(function () {});
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.loadAuction();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.limit = val;
      this.loadAuction();
    },
    loadNumberList: function loadNumberList(id, vId) {
      var _this12 = this;
      this.changeUser = {};
      this.showNumberList = [];
      (0, _auction.numberPlateUserList)({
        auction_id: id,
        page: 1,
        limit: 20,
        number_type: 2,
        venue_id: vId
      }).then(function (res) {
        _this12.showNumberList = res.data.list;
      }).catch(function () {});
    },
    clickNumberAction: function clickNumberAction(m) {
      this.changeUser = m;
    },
    goLiveUpAction: function goLiveUpAction(m) {
      var _this13 = this;
      this.$confirm('确认置顶此拍品？', '提示', {
        confirmButtonText: '置顶',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.setLiveTopAuction)({
          auction_id: m.product_id
        }).then(function () {
          _this13.$message.success('置顶成功');
          // this.load();
          _this13.list.forEach(function (item) {
            item.is_live_top = false;
          });
          m.is_live_top = true;
        });
      });
    }
  }
};
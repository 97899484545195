var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "title",
      style: {
        backgroundColor: _vm.facade.background_color,
        padding: "0 " + _vm.facade.page_padding / 2 + "px",
      },
    },
    [
      _c(
        "section",
        {
          staticClass: "title-style1",
          class: { "item-align-center": _vm.content.style <= 3 },
          style:
            _vm.content.style <= 3 ? "display:flex;align-items:center;" : "",
        },
        [
          _c(
            "section",
            {
              staticClass: "flex-one",
              class: { "item-align-center": _vm.content.style === 1 },
              staticStyle: { flex: "1" },
              style:
                _vm.content.style === 1
                  ? "display:flex;align-items:center;"
                  : "",
            },
            [
              _c(
                "section",
                {
                  staticClass: "title-content item-align-center",
                  class: {
                    "item-flex-center":
                      _vm.content.style === 4 || _vm.content.style === 5,
                  },
                  style: {
                    color: _vm.facade.title_color,
                    "font-weight": _vm.facade.title_weight,
                    "font-size": _vm.facade.title_font_size / 2 + "px",
                  },
                },
                [
                  _vm.content.title_img
                    ? _c("img", {
                        staticStyle: { "margin-right": "5px" },
                        style: {
                          width: _vm.facade.title_font_size / 2 + 4 + "px",
                          height: _vm.facade.title_font_size / 2 + 4 + "px",
                        },
                        attrs: {
                          src: _vm.content.title_img + "!120a",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("section", [_vm._v(_vm._s(_vm.content.title))]),
                ]
              ),
              _vm._v(" "),
              _vm.content.style === 1 || _vm.content.style === 3
                ? _c(
                    "section",
                    {
                      staticClass: "subtitle-content",
                      class: {
                        "item-align-center flex-one ml10 mt2":
                          _vm.content.style === 1,
                      },
                      style: {
                        color: _vm.facade.subtitle_color,
                        "font-weight": _vm.facade.subtitle_weight,
                        "font-size": _vm.facade.subtitle_font_size / 2 + "px",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.content.subtitle) + "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.content.style !== 4
            ? _c(
                "section",
                {
                  staticClass: "item-align-center more-content",
                  class: { "item-flex-center": _vm.content.style === 5 },
                  staticStyle: { display: "flex", "align-items": "center" },
                  style: {
                    color: _vm.facade.more_color,
                    "font-weight": _vm.facade.more_weight,
                    "font-size": _vm.facade.more_font_size / 2 + "px",
                  },
                },
                [
                  _c("section", { staticClass: "moretitle" }, [
                    _vm._v(_vm._s(_vm.content.moretitle)),
                  ]),
                  _vm._v(" "),
                  (_vm.content.style === 1 || _vm.content.style === 3) &&
                  _vm.facade.more_img === 1
                    ? _c("img", {
                        style: {
                          width: _vm.facade.more_font_size / 2 + "px",
                          height: _vm.facade.more_font_size / 2 + "px",
                        },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/a7355b2ef09b11794314a9f986be9f35.png",
                          alt: "",
                        },
                      })
                    : (_vm.content.style === 1 || _vm.content.style === 3) &&
                      _vm.facade.more_img === 2
                    ? _c("img", {
                        style: {
                          width: _vm.facade.more_font_size / 2 + "px",
                          height: _vm.facade.more_font_size / 2 + "px",
                        },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/3ebdd18efdf9f99cd873340c91c239fc.png",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.content.style === 5
                    ? _c("img", {
                        style: {
                          width: _vm.facade.more_font_size / 2 + "px",
                          height: _vm.facade.more_font_size / 2 + "px",
                        },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/45a77dffada667126effa371b1c1c6c9.png",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
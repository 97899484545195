"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAuction = addAuction;
exports.addUserNumberPlate = addUserNumberPlate;
exports.addVirtualClickNumApi = addVirtualClickNumApi;
exports.aiAuctionSList = aiAuctionSList;
exports.albumAuctionStatistical = albumAuctionStatistical;
exports.albumDetail = albumDetail;
exports.albumEditOrderApi = albumEditOrderApi;
exports.albumOptionList = albumOptionList;
exports.albumRelationListApi = albumRelationListApi;
exports.albumSearchList = albumSearchList;
exports.albumSearchListApi = albumSearchListApi;
exports.albumStatisticalInfoApi = albumStatisticalInfoApi;
exports.albumStatusNum = albumStatusNum;
exports.albumVisitListApi = albumVisitListApi;
exports.albumlist = albumlist;
exports.aroundUserChartsDataApi = aroundUserChartsDataApi;
exports.artistListApi = artistListApi;
exports.assignAdminApi = assignAdminApi;
exports.auctionBidRecordApi = auctionBidRecordApi;
exports.auctionBidRecordDataApi = auctionBidRecordDataApi;
exports.auctionBondListApi = auctionBondListApi;
exports.auctionConfirmUnsoldApi = auctionConfirmUnsoldApi;
exports.auctionDealStatusNum = auctionDealStatusNum;
exports.auctionDetail = auctionDetail;
exports.auctionEditOrder = auctionEditOrder;
exports.auctionEnd = auctionEnd;
exports.auctionList = auctionList;
exports.auctionPrivateDealApi = auctionPrivateDealApi;
exports.auctionSearchListApi = auctionSearchListApi;
exports.auctionStartEndApi = auctionStartEndApi;
exports.auctionStatusNum = auctionStatusNum;
exports.auctionUserBidListApi = auctionUserBidListApi;
exports.auctionVisitListApi = auctionVisitListApi;
exports.batchAddGroupNumber = batchAddGroupNumber;
exports.batchDeleteApi = batchDeleteApi;
exports.bidPrice = bidPrice;
exports.bidPriceApi = bidPriceApi;
exports.bidRangeList = bidRangeList;
exports.bondMemberInfoApi = bondMemberInfoApi;
exports.bondMemberUserDelApi = bondMemberUserDelApi;
exports.bondMemberUserEditStatusApi = bondMemberUserEditStatusApi;
exports.bondMemberUserListApi = bondMemberUserListApi;
exports.bondMemberUserSetApi = bondMemberUserSetApi;
exports.cancelBidPrice = cancelBidPrice;
exports.cancelPush = cancelPush;
exports.cancelPushTimeApi = cancelPushTimeApi;
exports.clearGroupNumberApi = clearGroupNumberApi;
exports.collectAuctionApi = collectAuctionApi;
exports.collectAuctionDelApi = collectAuctionDelApi;
exports.collectAuctionEditStatusApi = collectAuctionEditStatusApi;
exports.collectCreateApi = collectCreateApi;
exports.collectDetailApi = collectDetailApi;
exports.collectListApi = collectListApi;
exports.collectUpdateApi = collectUpdateApi;
exports.confirmBidPrice = confirmBidPrice;
exports.createAlbum = createAlbum;
exports.createPushForm = createPushForm;
exports.createSceneNumberPlateApi = createSceneNumberPlateApi;
exports.createTweetTemplateApi = createTweetTemplateApi;
exports.dealCategoryChartsDataApi = dealCategoryChartsDataApi;
exports.delAlbum = delAlbum;
exports.delAuction = delAuction;
exports.delBidPrice = delBidPrice;
exports.delUserNumberPlate = delUserNumberPlate;
exports.editAlbum = editAlbum;
exports.editAuction = editAuction;
exports.editAuctionBidRange = editAuctionBidRange;
exports.editSendUserObjectApi = editSendUserObjectApi;
exports.editTweetTemplateApi = editTweetTemplateApi;
exports.endAlbumApi = endAlbumApi;
exports.entrustCreateApi = entrustCreateApi;
exports.entrustDeleteApi = entrustDeleteApi;
exports.entrustDetailApi = entrustDetailApi;
exports.entrustUpdateApi = entrustUpdateApi;
exports.exemptBond = exemptBond;
exports.exportStatistical = exportStatistical;
exports.getAlbumLiveGroupNextInfoApi = getAlbumLiveGroupNextInfoApi;
exports.getAlbumLiveGroupStartInfoApi = getAlbumLiveGroupStartInfoApi;
exports.getCategory = getCategory;
exports.getEntrustUserList = getEntrustUserList;
exports.getLiveGroupPushUrlApi = getLiveGroupPushUrlApi;
exports.getNumberPlateNumApi = getNumberPlateNumApi;
exports.hideAuction = hideAuction;
exports.joinRoom = joinRoom;
exports.jumpAuction = jumpAuction;
exports.liveGroupListApi = liveGroupListApi;
exports.losePriceSwitchStatusApi = losePriceSwitchStatusApi;
exports.meetingCreateForm = meetingCreateForm;
exports.meetingDelete = meetingDelete;
exports.meetingDetail = meetingDetail;
exports.meetingEditForm = meetingEditForm;
exports.meetingList = meetingList;
exports.meetingUpdForm = meetingUpdForm;
exports.numberGroupCreate = numberGroupCreate;
exports.numberGroupDel = numberGroupDel;
exports.numberGroupEdit = numberGroupEdit;
exports.numberGroupList = numberGroupList;
exports.numberList = numberList;
exports.numberPlateUserList = numberPlateUserList;
exports.officialAccountAddMaterialApi = officialAccountAddMaterialApi;
exports.officialAccountDraftAddApi = officialAccountDraftAddApi;
exports.officialAccountDraftUpdateApi = officialAccountDraftUpdateApi;
exports.officialAccountUploadArticleImageApi = officialAccountUploadArticleImageApi;
exports.openCloudAuctionApi = openCloudAuctionApi;
exports.payServiceChargeApi = payServiceChargeApi;
exports.phoneEntrustListApi = phoneEntrustListApi;
exports.pmPlateApi = pmPlateApi;
exports.proxyBidConfirm = proxyBidConfirm;
exports.proxyBidListApi = proxyBidListApi;
exports.proxyPlatformAlbumListApi = proxyPlatformAlbumListApi;
exports.proxyPlatformListApi = proxyPlatformListApi;
exports.proxyPlatformSendSignSmsApi = proxyPlatformSendSignSmsApi;
exports.proxyPlatformSignApi = proxyPlatformSignApi;
exports.proxyPlatformSignStatusApi = proxyPlatformSignStatusApi;
exports.publishTweetApi = publishTweetApi;
exports.pushAreaNumStat = pushAreaNumStat;
exports.pushAuctionDataApi = pushAuctionDataApi;
exports.pushNoticeList = pushNoticeList;
exports.remakeAuction = remakeAuction;
exports.revokeAuction = revokeAuction;
exports.saveTweetApi = saveTweetApi;
exports.scanCodeUserList = scanCodeUserList;
exports.sceneBidPrice = sceneBidPrice;
exports.sendLiveNoticeApi = sendLiveNoticeApi;
exports.setAlbumProxyPlatformApi = setAlbumProxyPlatformApi;
exports.setAuctionArtistApi = setAuctionArtistApi;
exports.setLiveTopAuction = setLiveTopAuction;
exports.setPushTimeApi = setPushTimeApi;
exports.specialUserDealListApi = specialUserDealListApi;
exports.startNextAuction = startNextAuction;
exports.statisticalAuctionListApi = statisticalAuctionListApi;
exports.switchLiveGroupNextInfoApi = switchLiveGroupNextInfoApi;
exports.syncAlbumApi = syncAlbumApi;
exports.tweetCreateApi = tweetCreateApi;
exports.tweetDelApi = tweetDelApi;
exports.tweetInfoApi = tweetInfoApi;
exports.tweetListApi = tweetListApi;
exports.tweetTemplateDetailApi = tweetTemplateDetailApi;
exports.tweetTemplateListApi = tweetTemplateListApi;
exports.tweetUpdateApi = tweetUpdateApi;
exports.updateAlbumLiveGroupApi = updateAlbumLiveGroupApi;
exports.updateAlbumLiveGroupOrderApi = updateAlbumLiveGroupOrderApi;
exports.updateUserNumberPlate = updateUserNumberPlate;
exports.userAnalysisInfoApi = userAnalysisInfoApi;
exports.userAnalysisListApi = userAnalysisListApi;
exports.userAreaChartsDataApi = userAreaChartsDataApi;
exports.userBidNumRankingApi = userBidNumRankingApi;
exports.userIdeaNumRankingApi = userIdeaNumRankingApi;
exports.userIdeaPriceRankingApi = userIdeaPriceRankingApi;
exports.userNumberPlateChartsDataApi = userNumberPlateChartsDataApi;
exports.userSexChartsDataApi = userSexChartsDataApi;
exports.userSourceChartsDataApi = userSourceChartsDataApi;
exports.venueCreateApi = venueCreateApi;
exports.venueFilterNum = venueFilterNum;
exports.venueStatisticalInfoApi = venueStatisticalInfoApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * 拍卖会列表
 */
function meetingList(data) {
  return _request.default.get('auction/venue/list', data);
}

/**
 * 拍卖会详情
 */
function meetingDetail(id) {
  return _request.default.get("auction/venue/info/".concat(id));
}

/**
 * 删除拍卖会
 */
function meetingDelete(id) {
  return _request.default.post('auction/venue/del/' + id);
}
function exportStatistical(data) {
  return _request.default.post('auction/statistical/exportStatistical', data);
}
function updateUserNumberPlate(id, data) {
  return _request.default.post("auction/numberPlate/updateUserNumberPlate/".concat(id), data);
}

/**
 * 创建拍卖会表单
 */
function meetingCreateForm(id, data) {
  return _request.default.get('auction/venue/create/form', data);
}
function meetingUpdForm(id, data) {
  return _request.default.post("auction/venue/update/".concat(id), data);
}

/**
 * 修改拍卖会表单
 */
function meetingEditForm(id) {
  return _request.default.get('auction/venue/update/form/' + id);
}

/**
 * 专场列表
 */
function albumlist(data) {
  return _request.default.get("auction/album/list", data);
}

/**
 * 专场列表
 */
function albumSearchList(data) {
  return _request.default.get("auction/album/searchList", data);
}

/**
 * 专场列表(只返回id name)
 */
function albumSearchListApi(data) {
  return _request.default.get("auction/album/searchList", data);
}

/**
 * 拍品列表(只返回id name)
 */
function auctionSearchListApi(data) {
  return _request.default.get("auction/auction/searchList", data);
}
/**
 * 专场列表（选项列表）
 */
function albumOptionList(data) {
  return _request.default.get("auction/album/listOption", data);
}
/**
 * 拍品列表
 */
function auctionList(data) {
  return _request.default.get("auction/auction/list", data);
}
/**
 * 拍品列表
 */
function aiAuctionSList(data) {
  return _request.default.get("auction/auction/searchList", data);
}
/**
 * 艺术家列表
 */
function artistListApi(data) {
  return _request.default.get("auction/auction/artistList", data);
}
/**
 * 设置艺术家
 */
function setAuctionArtistApi(data) {
  return _request.default.post("auction/auction/setAuctionArtist", data);
}

/**
 * 拍品列表
 */
function statisticalAuctionListApi(data) {
  return _request.default.get("auction/statistical/auction_list", data);
}

/**
 * 拍品统计  出价列表
 */
function auctionBidRecordApi(data) {
  return _request.default.get("auction/statistical/auction_bid_record", data);
}

/**
 * 拍卖会专场数量
 */
function venueFilterNum() {
  return _request.default.get("auction/venue/venueTypeFilterNum");
}

/**
 * 专场 各个状态数量
 */
function albumStatusNum(id) {
  return _request.default.get("auction/album/statusFilterNum/".concat(id));
}

/**
 * 拍品 各个状态数量
 */
function auctionStatusNum(data) {
  return _request.default.get("auction/auction/timeStatusFilterNum", data);
}

/**
 * 拍品 各个成交数量
 */
function auctionDealStatusNum(data) {
  return _request.default.get("auction/auction/dealStatusFilterNum", data);
}

/**
 * 隐藏 展示拍品
 */
function hideAuction(id, data) {
  return _request.default.post('auction/auction/hide/' + id, data);
}

/**
 * 设置代理出价
 */
function proxyBidConfirm(data) {
  return _request.default.post('auction/proxyBid/add', data);
}

/**
 * 人工顶价
 */
function bidPrice(data) {
  return _request.default.post('auction/auction/bidPrice', data);
}

/**
 * 人工顶价
 */
function bidPriceApi(data) {
  return _request.default.post('auction/auction/bidPrice', data);
}

/**
 * 删除拍品
 */
function delAuction(id) {
  return _request.default.post('auction/auction/del/' + id);
}

/**
 * 竞价阶梯列表
 */
function bidRangeList(data) {
  return _request.default.get("auction/bidRange/list", data);
}

/**
 * 拍品分类列表
 */
function getCategory() {
  return _request.default.get("auction/category/list");
}

/**
 * 免保证金用户 免保号牌
 */
function exemptBond(data) {
  return _request.default.get("auction/venue/exemptBond/list", data);
}

/**
 * 创建专场
 */
function createAlbum(id, data) {
  return _request.default.post('auction/album/create', data);
}

// 专场详情
function albumDetail(id) {
  return _request.default.get("auction/album/info/".concat(id));
}

/**
 * 修改专场
 */
function editAlbum(id, data) {
  return _request.default.post("auction/album/update/".concat(id), data);
}
/**
 * 删除专场
 */
function delAlbum(id) {
  return _request.default.post("auction/album/del/".concat(id));
}
/**
 * 结束专场
 */
function endAlbumApi(data) {
  return _request.default.post("auction/album/endInstantlyAlbum", data);
}

// 发布拍品
function addAuction(id, data) {
  return _request.default.post("auction/auction/create", data);
}

// 编辑拍品
function editAuction(id, data) {
  return _request.default.post("auction/auction/update/".concat(id), data);
}

// 拍品详情
function auctionDetail(id) {
  return _request.default.get("auction/auction/info/".concat(id));
}

// 拍品排序
function auctionEditOrder(id, data) {
  return _request.default.post("auction/auction/editOrderNum/".concat(id), data);
}

// 推送列表
function pushNoticeList(data) {
  return _request.default.get("auction/pushTask/list", data);
}

// 推送范围数量
function pushAreaNumStat() {
  return _request.default.get("pushTask/pushChannelNumStat");
}

// 修改推送用户
function editSendUserObjectApi(data) {
  return _request.default.post("auction/pushTask/editSendUserObject", data);
}

// 修改推送时间
function setPushTimeApi(data) {
  return _request.default.post("auction/pushTask/setPushTime", data);
}

// 取消推送
function cancelPushTimeApi(data) {
  return _request.default.post("auction/pushTask/cancelSend", data);
}

// 创建拍卖会
function venueCreateApi(data) {
  return _request.default.post("/auction/venue/create", data);
}

// 取消推送
function cancelPush(id) {
  return _request.default.post("pushTask/cancel/".concat(id));
}

// 创建推送表单
function createPushForm(data) {
  return _request.default.get("pushTask/createForm", data);
}

// 保证金会员详情
function bondMemberInfoApi(data) {
  return _request.default.get("auction/bondMember/info", data);
}

// 买家号牌
function numberPlateUserList(data) {
  return _request.default.get("auction/numberPlate/list", data);
}

// 扫码办牌
function scanCodeUserList(data) {
  return _request.default.get("auction/numberPlate/applyList", data);
}

// 添加买家号牌
function addUserNumberPlate(data) {
  return _request.default.post("auction/numberPlate/addUserNumberPlate", data);
}

// 删除买家号牌
function delUserNumberPlate(id, data) {
  return _request.default.post("auction/numberPlate/delUserNumber/".concat(id), data);
}

// 现场办牌
function createSceneNumberPlateApi(data) {
  return _request.default.post("auction/numberPlate/createSceneNumberPlate", data);
}

// 号牌组列表
function numberGroupList(data) {
  return _request.default.get("auction/numberGroup/list", data);
}

// 号牌组号牌数量
function getNumberPlateNumApi(data) {
  return _request.default.get("auction/numberGroup/getNumberPlateNum");
}

// 添加号牌组
function numberGroupCreate(id, data) {
  return _request.default.post("auction/numberGroup/create", data);
}

// 清空号牌组
function clearGroupNumberApi(data) {
  return _request.default.post("auction/numberGroup/clearGroupNumber", data);
}

// 修改号牌组
function numberGroupEdit(id, data) {
  return _request.default.post("auction/numberGroup/update/".concat(id), data);
}
// 删除号牌组
function numberGroupDel(id) {
  return _request.default.post("auction/numberGroup/del/".concat(id));
}

// 删除号牌组
function batchAddGroupNumber(data) {
  return _request.default.post("auction/numberGroup/batchAddGroupNumber", data);
}

// 号牌列表
function numberList(data) {
  return _request.default.get("auction/numberGroup/numberList", data);
}

// 专场数据
function albumAuctionStatistical(data) {
  return _request.default.get("auction/SynAuction/albumAuctionStatistical", data);
}

// 专场关注数据
function albumRelationListApi(data) {
  return _request.default.get("auction/statistical/album_relation_list", data);
}

// 专场统计数据
function albumStatisticalInfoApi(data) {
  return _request.default.get("auction/statistical/AlbumStatisticalInfo", data);
}

// 修改虚拟销量
function addVirtualClickNumApi(data) {
  return _request.default.post("auction/album/addVirtualClickNum", data);
}

// 修改专场排序
function albumEditOrderApi(data) {
  return _request.default.post("auction/album/editOrderNum", data);
}
// 专场下拍品的访问记录
function albumVisitListApi(data) {
  return _request.default.get("auction/statistical/album_visit_list", data);
}
// 拍品的访问记录
function auctionVisitListApi(data) {
  return _request.default.get("auction/statistical/auction_visit_list", data);
}
// 拍卖会统计详情
function venueStatisticalInfoApi(data) {
  return _request.default.get("auction/statistical/venueStatisticalInfo", data);
}
// 买家成交额排行
function userIdeaPriceRankingApi(data) {
  return _request.default.get("auction/statistical/userIdeaPriceRanking", data);
}
// 买家成交数量排行
function userIdeaNumRankingApi(data) {
  return _request.default.get("auction/statistical/userIdeaNumRanking", data);
}
// 买家出价次数排行
function userBidNumRankingApi(data) {
  return _request.default.get("auction/statistical/userBidNumRanking", data);
}
// 成交类目占比
function dealCategoryChartsDataApi(data) {
  return _request.default.get("auction/statistical/dealCategoryChartsData", data);
}
// 买家地域占比
function userAreaChartsDataApi(data) {
  return _request.default.get("auction/statistical/userAreaChartsData", data);
}
// 买家性别占比
function userSexChartsDataApi(data) {
  return _request.default.get("auction/statistical/userSexChartsData", data);
}
// 新老买家占比
function aroundUserChartsDataApi(data) {
  return _request.default.get("auction/statistical/aroundUserChartsData", data);
}
// 网络 现场买家占比
function userSourceChartsDataApi(data) {
  return _request.default.get("auction/statistical/userSourceChartsData", data);
}
// 普通号牌 免保号牌占比
function userNumberPlateChartsDataApi(data) {
  return _request.default.get("auction/statistical/userNumberPlateChartsData", data);
}

/** 拍卖控制台  */

// 拍卖现场出价
function sceneBidPrice(data) {
  return _request.default.post("auction/SynAuction/sceneBidPrice", data);
}
// 加入直播间
function joinRoom(data) {
  return _request.default.post("auction/SynAuction/joinRoom", data);
}
// 确认网络出价
function confirmBidPrice(data) {
  return _request.default.post("auction/SynAuction/confirmBidPrice", data);
}
// 开始下一件拍品
function startNextAuction(data) {
  return _request.default.post("auction/SynAuction/startNextAuction", data);
}
// 结束拍品
function auctionEnd(data) {
  return _request.default.post("auction/SynAuction/auctionEnd", data);
}
// 删除出价
function delBidPrice(data) {
  return _request.default.post("auction/SynAuction/delBidPrice", data);
}
// 取消出价
function cancelBidPrice(data) {
  return _request.default.post("auction/SynAuction/cancelBidPrice", data);
}
// 重拍
function remakeAuction(data) {
  return _request.default.post("auction/SynAuction/remakeAuction", data);
}
// 跳拍
function jumpAuction(data) {
  return _request.default.post("auction/SynAuction/jumpAuction", data);
}
// 撤拍
function revokeAuction(data) {
  return _request.default.post("auction/SynAuction/revokeAuction", data);
}
// 获取拍品代理出价列表
function proxyBidListApi(data) {
  return _request.default.get("auction/proxyBid/list", data);
}
// 修改拍品的竞价阶梯
function editAuctionBidRange(data) {
  return _request.default.post("auction/SynAuction/editBidRange", data);
}
// 发送通知
function sendLiveNoticeApi(data) {
  return _request.default.post("auction/SynAuction/sendLiveNotice", data);
}
// 开始结束拍品
function auctionStartEndApi(data) {
  return _request.default.post("auction/SynAuction/auctionStartEnd", data);
}
// 直播间置顶拍品
function setLiveTopAuction(data) {
  return _request.default.post("auction/auction/setLiveTopAuction", data);
}

// 买家分析
function userAnalysisListApi(data) {
  return _request.default.get("auction/statistical/userAnalysisList", data);
}
// 买家分析详情
function userAnalysisInfoApi(data) {
  return _request.default.get("auction/statistical/userAnalysisInfo", data);
}

// 直播专场分组
function liveGroupListApi(data) {
  return _request.default.get("auction/album/liveGroupList", data);
}

// 修改直播专场分组
function updateAlbumLiveGroupApi(data) {
  return _request.default.post("auction/album/updateAlbumLiveGroup", data);
}

// 修改直播专场分组排序
function updateAlbumLiveGroupOrderApi(data) {
  return _request.default.post("auction/album/updateAlbumLiveGroupOrder", data);
}

// 获取直播组内正在进行中或者预展中的专场
function getAlbumLiveGroupStartInfoApi(data) {
  return _request.default.get("auction/album/getAlbumLiveGroupStartInfo", data);
}

// 获取直播组下一场专场信息
function getAlbumLiveGroupNextInfoApi(data) {
  return _request.default.get("auction/album/getAlbumLiveGroupNextInfo", data);
}

// 直播组切换下一个专场
function switchLiveGroupNextInfoApi(data) {
  return _request.default.post("auction/album/switchLiveGroupNextInfo", data);
}

// 直播组获取直播流
function getLiveGroupPushUrlApi(data) {
  return _request.default.get("auction/album/getLiveGroupPushUrl", data);
}

// 推文列表
function tweetListApi(data) {
  return _request.default.get("auction/tweet/list", data);
}

// 推文详情
function tweetInfoApi(id) {
  return _request.default.get("auction/tweet/info/".concat(id));
}

// 推文修改
function tweetUpdateApi(id, data) {
  return _request.default.post("auction/tweet/update/".concat(id), data);
}

// 推文创建
function tweetCreateApi(id, data) {
  return _request.default.post("auction/tweet/create", data);
}

// 推文删除
function tweetDelApi(id) {
  return _request.default.post("auction/tweet/delete/".concat(id));
}

// 私洽成交
function auctionPrivateDealApi(data) {
  return _request.default.post("auction/auction/auctionPrivateDeal", data);
}

// 确认流拍
function auctionConfirmUnsoldApi(data) {
  return _request.default.post("auction/auction/auctionConfirmUnsold", data);
}

// 特殊号牌成交统计
function specialUserDealListApi(data) {
  return _request.default.get("auction/statistical/specialUserDealList", data);
}

// 拍品页面 出价记录
function auctionBidRecordDataApi(data) {
  return _request.default.get("auction/auction/auction_bid_record", data);
}

// 专场保证金列表
function auctionBondListApi(data) {
  return _request.default.get("auction/auction/auctionBondList", data);
}

// 用户出价列表
function auctionUserBidListApi(data) {
  return _request.default.get("auction/auction/getUserBidList", data);
}
// 开通云代拍
function openCloudAuctionApi(data) {
  return _request.default.post("/cloudSynch/open", data);
}
// 同步专场
function syncAlbumApi(data) {
  return _request.default.post("/cloudSynch/pm/album", data);
}

// 征集列表
function collectListApi(data) {
  return _request.default.get('collect/lst', data);
}

// 征集活动详情
function collectDetailApi(id, data) {
  return _request.default.get("collect/detail/".concat(id), data);
}

// 创建征集活动
function collectCreateApi(id, data) {
  return _request.default.post('collect/create', data);
}

// 修改征集活动
function collectUpdateApi(id, data) {
  return _request.default.post("collect/update/".concat(id), data);
}

// 征集活动 下的拍品列表
function collectAuctionApi(data) {
  return _request.default.get("collect/record/lst", data);
}

// 征集活动 修改上拍状态
function collectAuctionEditStatusApi(id, data) {
  return _request.default.post("collect/record/status/".concat(id), data);
}

// 征集活动 删除
function collectAuctionDelApi(id) {
  return _request.default.delete("collect/delete/".concat(id));
}

// 免保会员
function bondMemberUserListApi(data) {
  return _request.default.get("auction/bondMember/list", data);
}

// 免保会员 - 设置
function bondMemberUserSetApi(data) {
  return _request.default.post("auction/bondMember/set", data);
}

// 免保会员 - 删除
function bondMemberUserDelApi(data) {
  return _request.default.post("auction/bondMember/del", data);
}

// 免保会员 - 修改状态
function bondMemberUserEditStatusApi(data) {
  return _request.default.post("auction/bondMember/editStatus", data);
}

// 拍卖会专场的代拍平台列表
function proxyPlatformAlbumListApi(data) {
  return _request.default.get("auction/proxyPlatform/albumList", data);
}

// 是否签约代拍平台协议
function proxyPlatformSignStatusApi(data) {
  return _request.default.get("auction/proxyPlatform/signStatus", data);
}

// 签约代拍平台协议 - 发送验证码
function proxyPlatformSendSignSmsApi(data) {
  return _request.default.post("auction/proxyPlatform/sendSignSms", data);
}

// 签约代拍平台协议 - 签约
function proxyPlatformSignApi(data) {
  return _request.default.post("auction/proxyPlatform/sign", data);
}

// 代拍平台列表
function proxyPlatformListApi(data) {
  return _request.default.get("auction/proxyPlatform/albumConfigList", data);
}

// 与代拍平台合作
function setAlbumProxyPlatformApi(data) {
  return _request.default.post("auction/proxyPlatform/setAlbumProxyPlatform", data);
}

// 往代拍平台推送数据
function pushAuctionDataApi(data) {
  return _request.default.post("auction/proxyPlatform/pushAuctionData", data);
}

// 支付代拍平台服务费
function payServiceChargeApi(data) {
  return _request.default.post("auction/proxyPlatform/payServiceCharge", data);
}

// 微信公众号 上传文章内图片
function officialAccountUploadArticleImageApi(data) {
  return _request.default.post("wechat/officialAccount/uploadArticleImage", data);
}

// 微信公众号 上传素材
function officialAccountAddMaterialApi(data) {
  return _request.default.post("wechat/officialAccount/addMaterial", data);
}

// 微信公众号 新增草稿
function officialAccountDraftAddApi(data) {
  return _request.default.post("wechat/officialAccount/draftAdd", data);
}

// 微信公众号 修改草稿
function officialAccountDraftUpdateApi(data) {
  return _request.default.post("wechat/officialAccount/draftUpdate", data);
}

// 电话委托列表
function phoneEntrustListApi(data) {
  return _request.default.get("auction/phoneEntrust/list", data);
}

// 电话委托详情
function entrustDetailApi(id) {
  return _request.default.get("auction/phoneEntrust/detail/".concat(id));
}

// 电话委托录入
function entrustCreateApi(data) {
  return _request.default.post("auction/phoneEntrust/batchCreate", data);
}

// 电话委托编辑
function entrustUpdateApi(id, data) {
  return _request.default.post("auction/phoneEntrust/update/".concat(id), data);
}

// 电话委托删除
function entrustDeleteApi(id) {
  return _request.default.post("auction/phoneEntrust/delete/".concat(id));
}

// 分配员工
function assignAdminApi(id, data) {
  return _request.default.post("auction/phoneEntrust/assignAdmin/".concat(id), data);
}

// 导入号牌库
function pmPlateApi(data) {
  return _request.default.post("excel/import/import_pm_number_plate", data);
}
// 创建推文模板
function createTweetTemplateApi(data) {
  return _request.default.post("marketMedia/tweetTemplate/create", data);
}

// 修改推文模板
function editTweetTemplateApi(data) {
  return _request.default.post("marketMedia/tweetTemplate/update", data);
}

// 模板详情
function tweetTemplateDetailApi(data) {
  return _request.default.get("marketMedia/tweetTemplate/info", data);
}

// 模板列表
function tweetTemplateListApi(data) {
  return _request.default.get("marketMedia/tweetTemplate/list", data);
}

// 保存推文
function saveTweetApi(id, data) {
  return _request.default.post("marketMedia/saveMarketTweet/".concat(id), data);
}

// 发布推文 marketMedia/publishTweet/${id} /mer/marketMedia/releaseMarketTweet/{id}
function publishTweetApi(id, data) {
  return _request.default.post("marketMedia/releaseMarketTweet/".concat(id), data);
}
// 隐藏成交价
function losePriceSwitchStatusApi(data) {
  return _request.default.post("auction/auction/losePriceSwitchStatus", data);
}

// 批量删除货品档案
function batchDeleteApi(data) {
  return _request.default.post("auction/auction/batchDelete", data);
}

// 拍卖会下委托人列表
function getEntrustUserList(data) {
  return _request.default.get("auction/auction/getEntrustUserList", data);
}
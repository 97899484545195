var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "realTable mt20" },
    [
      _vm.venue_id
        ? _c(
            "div",
            { staticClass: "flex justify-b align-items-c album-filter" },
            [
              _c(
                "el-select",
                {
                  staticClass: "w300",
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeSelect },
                  model: {
                    value: _vm.albumId,
                    callback: function ($$v) {
                      _vm.albumId = $$v
                    },
                    expression: "albumId",
                  },
                },
                _vm._l(_vm.albumlist, function (m, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: {
                      label:
                        m.album_name +
                        " " +
                        (m.LotArea.min && m.LotArea.max
                          ? "(LOT" +
                            m.LotArea.min +
                            "-" +
                            m.LotArea.max +
                            "," +
                            "共" +
                            m.auction_num +
                            "件" +
                            ")"
                          : "(共" + m.auction_num + "件)"),
                      value: m.album_id,
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bor flex align-items-c ft12" }, [
                _c(
                  "div",
                  {
                    staticClass: "cur",
                    on: { click: _vm.showAlbumVisitDataAction },
                  },
                  [
                    _c(
                      "el-button",
                      { staticClass: "fWeight500", attrs: { type: "text" } },
                      [_vm._v(_vm._s(_vm.albumData.click_num_uv || 0))]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("人看过")]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      { staticClass: "fWeight500", attrs: { type: "text" } },
                      [_vm._v(_vm._s(_vm.albumData.click_num_uv_product || 0))]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("件拍品")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "cur ml30",
                    on: { click: _vm.showProxyBidDataAction },
                  },
                  [
                    _c(
                      "el-button",
                      { staticClass: "fWeight500", attrs: { type: "text" } },
                      [_vm._v(_vm._s(_vm.albumData.proxy_bid_uv || 0))]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("人设置")]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      { staticClass: "fWeight500", attrs: { type: "text" } },
                      [_vm._v(_vm._s(_vm.albumData.proxy_bid_num || 0))]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("次代理出价")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.albumData.album_care_count
                  ? _c(
                      "div",
                      {
                        staticClass: "cur ml30",
                        on: { click: _vm.showAlbumRelationDataAction },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "fWeight500",
                            attrs: { type: "text" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.albumData.album_care_count.uv || 0)
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("人设置")]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "fWeight500",
                            attrs: { type: "text" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.albumData.album_care_count.num || 0)
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("次提醒")]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 225px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20 ft12",
              attrs: {
                height: "100%",
                data: _vm.list,
                "element-loading-text": "Loading",
                fit: "",
                border: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "图片",
                  width: "50",
                  align: "center",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticStyle: {
                            width: "25px",
                            "margin-top": "5px",
                            cursor: "pointer",
                          },
                          attrs: {
                            src:
                              scope.row.product.image.split(",")[0] + "!120a",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showImageAction(
                                scope.row.product.image.split(",")
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "LOT",
                  width: "60",
                  prop: "",
                  "search-key": "lot",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n        " + _vm._s(scope.row.lot) + "\n      "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍品名称",
                  "min-width": "100",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "store_name",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.product.store_name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "状态",
                  width: "60",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auction_status == -2
                          ? _c("div", { staticClass: "ft14" }, [
                              _vm._v("已撤拍"),
                            ])
                          : scope.row.auction_status == 1
                          ? _c("div", { staticClass: "lv ft14" }, [
                              _vm._v("进行中"),
                            ])
                          : scope.row.auction_status == 2
                          ? _c("div", { staticClass: "lv ft14" }, [
                              _vm._v("待开拍"),
                            ])
                          : scope.row.auction_status > 2
                          ? _c("div", { staticClass: "ft14" }, [
                              _vm._v("已结拍"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍卖时间",
                  width: "170",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        ((scope.row.auction_status == 1 &&
                          scope.row.end_time) ||
                          (scope.row.auction_status == 2 &&
                            scope.row.start_time)) &&
                        scope.row.time.goTime > 0
                          ? _c(
                              "div",
                              [
                                scope.row.end_time
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex align-items-c ft14",
                                        },
                                        [
                                          _c("div", { staticClass: "lv" }, [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.auction_status == 1
                                                  ? "剩余："
                                                  : "距开拍："
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          scope.row.time && scope.row.time.day
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "lv" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(scope.row.time.day)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", [_vm._v("天")]),
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          scope.row.time && scope.row.time.hour
                                            ? [
                                                _c(
                                                  "div",
                                                  { staticClass: "lv" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.time.hour
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", [_vm._v("时")]),
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "lv" }, [
                                            _vm._v(
                                              _vm._s(scope.row.time.minite)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [_vm._v("分")]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "lv" }, [
                                            _vm._v(
                                              _vm._s(scope.row.time.second)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [_vm._v("秒")]),
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.auction_status > 2 ||
                        scope.row.auction_status == -1
                          ? _c("div", { staticClass: "ft14" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.parseTime(
                                    scope.row.end_time * 1000,
                                    "{m}-{d} {h}:{i}:{s}"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "当前价",
                  width: "120",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.number
                          ? _c("div", [
                              _vm._v(
                                "#" + _vm._s(scope.row.number.number) + " "
                              ),
                              scope.row.number.number_type == "特殊号牌"
                                ? _c("span", [_vm._v("特")])
                                : _vm._e(),
                              scope.row.number.number_type == "免保号牌"
                                ? _c("span", [_vm._v("免")])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.now_price != 0
                          ? _c(
                              "div",
                              {
                                staticClass: "color-black ft14 fWeight500",
                                staticStyle: { color: "red" },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.albumData.fee_type.replace(
                                        "CNY",
                                        "RMB"
                                      )
                                    ) +
                                    "\n          " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        scope.row.now_price,
                                        2
                                      )
                                    )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "出价人",
                  width: "170",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user
                          ? [
                              scope.row.user.nickname
                                ? _c(
                                    "div",
                                    { staticClass: "ft14 nowrap" },
                                    [
                                      scope.row.user.uid == 8888
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: { padding: "0" },
                                              attrs: { type: "text" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(scope.row.user.nickname)
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                padding: "0",
                                                "text-align": "left",
                                              },
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onDetails(
                                                    scope.row.now_user
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.user.nickname
                                                  ) +
                                                  " "
                                              ),
                                              scope.row.user.mark
                                                ? _c("span", [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(
                                                          scope.row.user.mark
                                                        ) +
                                                        ")"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row.user
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "8px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.user
                                                            .real_name
                                                        ) +
                                                          "  " +
                                                          _vm._s(
                                                            scope.row.user.phone
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托",
                  width: "170",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.maxProxyBid
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      padding: "0",
                                      "text-align": "left",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(
                                          scope.row.maxProxyBid.user.uid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.maxProxyBid.user.real_name
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                scope.row.maxProxyBid.number
                                  ? _c("span", [
                                      _vm._v(
                                        "#" +
                                          _vm._s(
                                            scope.row.maxProxyBid.number.number
                                          ) +
                                          ": "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-black ft14 fWeight500",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          scope.row.maxProxyBid.proxy_price,
                                          2
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.entrustPhoneData
                          ? _vm._l(
                              scope.row.entrustPhoneData,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.entrustUser.real_name
                                          ? item.entrustUser.real_name
                                          : item.entrustUser.nickname
                                      ) +
                                      ": "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "color-black ft14 fWeight500",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            item.entrust_price,
                                            2
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "保留价",
                  width: "90",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.retain_price != 0
                          ? _c(
                              "div",
                              { staticClass: "color-black ft14 fWeight500" },
                              [
                                _vm._v(
                                  "RMB " +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        scope.row.retain_price,
                                        2
                                      )
                                    )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "当前在线",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showRecordAction(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "fWeight500",
                                attrs: { type: "text" },
                              },
                              [_vm._v(_vm._s(scope.row.online_num))]
                            ),
                            _c("span", [_vm._v("人在线")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "出价记录",
                  "min-width": "150",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showAuctionVisitDataAction(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "fWeight500 ml5",
                                staticStyle: {
                                  "padding-top": "3px",
                                  "padding-bottom": "3px",
                                },
                                attrs: { type: "text", size: "mini" },
                              },
                              [_vm._v(_vm._s(scope.row.click_uv))]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("人看过")]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            on: {
                              click: function ($event) {
                                return _vm.showRecordAction(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "fWeight500 ml5",
                                staticStyle: {
                                  "padding-top": "3px",
                                  "padding-bottom": "3px",
                                },
                                attrs: { type: "text", size: "mini" },
                              },
                              [_vm._v(_vm._s(scope.row.bid_num_uv))]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("人")]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "fWeight500",
                                staticStyle: {
                                  "padding-top": "3px",
                                  "padding-bottom": "3px",
                                },
                                attrs: { type: "text", size: "mini" },
                              },
                              [_vm._v(_vm._s(scope.row.bid_num))]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("次出价")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.isNo
                ? _c("ytx-table-column", {
                    attrs: {
                      label: "操作",
                      width: "90",
                      fixed: "right",
                      align: "center",
                      "search-clear": true,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.auction_status === 1 ||
                              scope.row.auction_status === 2
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "0 10px" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.proxyBidAction(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("\n          委托出价\n        ")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (scope.row.auction_status === 1 ||
                                scope.row.auction_status === 2) &&
                              !scope.row.is_live_top &&
                              _vm.liveCount > 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "margin-left": "0",
                                        padding: "0",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goLiveUpAction(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("\n          直播间置顶\n        ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3254348263
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "委托出价",
            visible: _vm.proxyData.show,
            "close-on-click-modal": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.proxyData, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c fWeight500" }, [
            _vm._v(
              "\n      拍品：" +
                _vm._s(_vm.proxyData.tuluhao) +
                " | " +
                _vm._s(_vm.proxyData.title) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("委托类型：")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.proxyData.bid_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.proxyData, "bid_type", $$v)
                    },
                    expression: "proxyData.bid_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("加一口")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("代理出价")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt20" }, [
            _c("span", { staticClass: "w100" }, [_vm._v("特殊号牌：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ts-code flex flex-one" },
              _vm._l(_vm.showNumberList, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "showNumberList" + i,
                    staticClass: "item flex align-items-c",
                    class: _vm.changeUser.uid == m.uid ? "curr" : "",
                    on: {
                      click: function ($event) {
                        return _vm.clickNumberAction(m)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "l-name line1" }, [
                      _vm._v(_vm._s(m.user.nickname)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "l-code ml10" }, [
                      _vm._v(_vm._s(m.number)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          !_vm.changeUser.uid
            ? _c(
                "div",
                { staticClass: "flex align-items-c mt20" },
                [
                  _c("span", { staticClass: "w100" }, [_vm._v("出价人：")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "输入出价人昵称/手机/真实姓名",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      model: {
                        value: _vm.proxyData.uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.proxyData, "uid", $$v)
                        },
                        expression: "proxyData.uid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar flex align-items-c" },
                            [
                              _c("img", { attrs: { src: m.avatar, alt: "" } }),
                              _vm._v(" "),
                              _c("div", { staticClass: "line1 flex-one" }, [
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(m.real_name || m.nickname)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(m.phone))]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("输入价格：")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w200",
                attrs: { min: 0 },
                model: {
                  value: _vm.proxyData.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.proxyData, "price", $$v)
                  },
                  expression: "proxyData.price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.changeUser.uid
            ? _c(
                "div",
                { staticClass: "flex align-items-c mt20" },
                [
                  _c("span", { staticClass: "w100" }, [
                    _vm._v("随机号牌出价："),
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.proxyData.anonymous,
                      callback: function ($$v) {
                        _vm.$set(_vm.proxyData, "anonymous", $$v)
                      },
                      expression: "proxyData.anonymous",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-btn mt40" },
            [
              _c("el-button", { on: { click: _vm.clearProxyBid } }, [
                _vm._v("\n        取消\n      "),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.proxyData.btnLoading },
                  on: { click: _vm.proxyBidConfirmAction },
                },
                [_vm._v("\n        确认\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: { size: "60%", visible: _vm.showBidRecord, direction: "rtl" },
          on: {
            "update:visible": function ($event) {
              _vm.showBidRecord = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "drawer-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm._v(
                "LOT" +
                  _vm._s(_vm.showBidRecordData.data.lot) +
                  " " +
                  _vm._s(_vm.showBidRecordData.data.product.store_name)
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "record-content lotTable" }, [
            _c(
              "div",
              { staticClass: "table-view" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.showLoading,
                        expression: "showLoading",
                      },
                    ],
                    staticClass: "color-black",
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      data: _vm.showBidRecordData.list,
                      "element-loading-text": "Loading",
                      fit: "",
                      height: "90%",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _c("div", { staticClass: "tableEmpty" }, [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tableDiv" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "买家信息", "min-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.user
                                ? _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", { staticClass: "avatar-img" }, [
                                        _c("img", {
                                          attrs: {
                                            src: row.user.avatar,
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-info flex-one ml10",
                                          staticStyle: { overflow: "hidden" },
                                        },
                                        [
                                          row.user.real_name
                                            ? _c("div", [
                                                _vm._v(
                                                  _vm._s(row.user.real_name)
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(row.user.nickname) + " "
                                            ),
                                            row.number
                                              ? _c("span", [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        row.number.number_type
                                                      ) +
                                                      ")"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                          _vm._v(" "),
                                          row.user.phone
                                            ? _c("div", [
                                                _vm._v(_vm._s(row.user.phone)),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          row.user.mark
                                            ? _c("div", [
                                                _vm._v(_vm._s(row.user.mark)),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "UID:" + _vm._s(row.user.uid)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "状态",
                        align: "center",
                        "min-width": "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.is_online
                                ? _c("div", { staticClass: "lv" }, [
                                    _vm._v("在线"),
                                  ])
                                : _c("div", { staticClass: "color-gray" }, [
                                    _vm._v("已离开"),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "号牌/保证金", "min-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "div",
                                { staticClass: "set-line" },
                                [
                                  row.number
                                    ? _c("div", [
                                        _vm._v(
                                          "号牌：" + _vm._s(row.number.number)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.pay_bond && row.pay_bond.length > 0
                                    ? _vm._l(
                                        row.pay_bond,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(item.type) +
                                                  "：" +
                                                  _vm._s(
                                                    _vm.formatPriceNumber(
                                                      item.price,
                                                      2
                                                    )
                                                  ) +
                                                  "元"
                                              ),
                                            ]),
                                          ])
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.proxy_info
                                    ? [
                                        _c("div", [
                                          _vm._v(
                                            "代理出价：" +
                                              _vm._s(
                                                _vm.formatPriceNumber(
                                                  Number(
                                                    row.proxy_info.proxy_price
                                                  )
                                                )
                                              ) +
                                              "元"
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "出价记录", "min-width": "180" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "bid-record-list" },
                                [
                                  scope.row.bid_record &&
                                  scope.row.bid_record.length > 0
                                    ? _vm._l(
                                        scope.row.bid_record,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass:
                                                "flex align-items-c line22",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "p",
                                                  class: {
                                                    curr:
                                                      index == 0 &&
                                                      scope.$index == 0,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatPriceNumber(
                                                        item.bid_price,
                                                        2
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "l",
                                                  class: {
                                                    curr:
                                                      index == 0 &&
                                                      scope.$index == 0,
                                                  },
                                                },
                                                [_vm._v("领先")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "flex-one" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.create_time)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "60%",
            visible: _vm.proxyBidData.show,
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.proxyBidData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "drawer-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm._v(
                "共" +
                  _vm._s(_vm.proxyBidData.uv) +
                  "人针对本场" +
                  _vm._s(_vm.proxyBidData.num) +
                  "件拍品设置了代理出价"
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "record-content lotTable" }, [
            _c(
              "div",
              { staticClass: "table-view" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "color-black",
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      data: _vm.proxyBidData.list,
                      "element-loading-text": "Loading",
                      fit: "",
                      height: "90%",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _c("div", { staticClass: "tableEmpty" }, [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tableDiv" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "买家信息", "min-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "avatar-img" }, [
                                  _c("img", {
                                    attrs: { src: row.user.avatar, alt: "" },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "user-info flex-one ml10",
                                    staticStyle: { overflow: "hidden" },
                                  },
                                  [
                                    row.user.real_name
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.real_name)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(_vm._s(row.user.nickname)),
                                    ]),
                                    _vm._v(" "),
                                    row.user.phone
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.phone)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.user.mark
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.mark)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("UID:" + _vm._s(row.user.uid)),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "本场号牌",
                        "min-width": "80",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.number
                                ? [
                                    row.number.number_type
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(row.number.number_type)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.number.number
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.number.number)),
                                        ])
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "代理价金额",
                        "min-width": "80",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "color-gray" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      Number(row.proxy_price)
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "拍品信息", "min-width": "140" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.auction && row.auction.product
                                ? _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", { staticClass: "avatar-img" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              row.auction.product.image +
                                              "!120a",
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-info flex-one ml10",
                                          staticStyle: { overflow: "hidden" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "LOT" +
                                                _vm._s(row.auction.lot) +
                                                " " +
                                                _vm._s(
                                                  row.auction.product.store_name
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "设置时间",
                        "min-width": "120",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [_vm._v(_vm._s(row.create_time))]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt20 flex justify-e" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "page-size": _vm.proxyBidData.limit,
                        "current-page": _vm.proxyBidData.page,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.proxyBidData.total,
                      },
                      on: { "current-change": _vm.proxyPageChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "60%",
            visible: _vm.albumVisitData.show,
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.albumVisitData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "drawer-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm._v(
                "共" +
                  _vm._s(_vm.albumVisitData.uv) +
                  "人观看过本场" +
                  _vm._s(_vm.albumVisitData.num) +
                  "次拍品"
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "record-content lotTable" }, [
            _c(
              "div",
              { staticClass: "table-view" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "color-black",
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      data: _vm.albumVisitData.list,
                      "element-loading-text": "Loading",
                      fit: "",
                      height: "90%",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _c("div", { staticClass: "tableEmpty" }, [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tableDiv" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "用户信息", "min-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "avatar-img" }, [
                                  _c("img", {
                                    attrs: { src: row.user.avatar, alt: "" },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "user-info flex-one ml10",
                                    staticStyle: { overflow: "hidden" },
                                  },
                                  [
                                    row.user.real_name
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.real_name)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(_vm._s(row.user.nickname)),
                                    ]),
                                    _vm._v(" "),
                                    row.user.phone
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.phone)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.user.mark
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.mark)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("UID:" + _vm._s(row.user.uid)),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "查看次数",
                        "min-width": "80",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "color-gray" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      Number(row.think_count)
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "拍品信息", "min-width": "140" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.auction && row.product
                                ? _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", { staticClass: "avatar-img" }, [
                                        _c("img", {
                                          attrs: {
                                            src: row.product.image + "!120a",
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-info flex-one ml10",
                                          staticStyle: { overflow: "hidden" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "LOT" +
                                                _vm._s(row.auction.lot) +
                                                " " +
                                                _vm._s(row.product.store_name)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "最后浏览时间",
                        "min-width": "120",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [_vm._v(_vm._s(row.max_create_time))]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt20 flex justify-e" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "page-size": _vm.query.limit,
                        "current-page": _vm.query.page,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.otherTotal,
                      },
                      on: { "current-change": _vm.pageOtherChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "60%",
            visible: _vm.auctionVisitData.show,
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.auctionVisitData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "drawer-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm._v(
                "LOT" +
                  _vm._s(_vm.auctionVisitData.lot) +
                  " " +
                  _vm._s(_vm.auctionVisitData.store_name) +
                  " 共" +
                  _vm._s(_vm.otherTotal) +
                  "人观看过"
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "record-content lotTable" }, [
            _c(
              "div",
              { staticClass: "table-view" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "color-black",
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      data: _vm.auctionVisitData.list,
                      "element-loading-text": "Loading",
                      fit: "",
                      height: "90%",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _c("div", { staticClass: "tableEmpty" }, [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tableDiv" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "用户信息", "min-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "avatar-img" }, [
                                  _c("img", {
                                    attrs: { src: row.user.avatar, alt: "" },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "user-info flex-one ml10",
                                    staticStyle: { overflow: "hidden" },
                                  },
                                  [
                                    row.user.real_name
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.real_name)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(_vm._s(row.user.nickname)),
                                    ]),
                                    _vm._v(" "),
                                    row.user.phone
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.phone)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.user.mark
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.mark)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("UID:" + _vm._s(row.user.uid)),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "来源",
                        "min-width": "100",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "color-gray" }, [
                                _vm._v("来自" + _vm._s(row.source_type_text)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "行为", "min-width": "140" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.is_care
                                ? _c("div", [_vm._v("关注")])
                                : _c("div", [_vm._v("看过")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "浏览时间",
                        "min-width": "120",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [_vm._v(_vm._s(row.create_time))]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt20 flex justify-e" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "page-size": _vm.query.limit,
                        "current-page": _vm.query.page,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.otherTotal,
                      },
                      on: { "current-change": _vm.pageOtherChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "60%",
            visible: _vm.albumRelationData.show,
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.albumRelationData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "drawer-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm._v(
                "共" +
                  _vm._s(_vm.albumRelationData.uv) +
                  "人针对本场设置了" +
                  _vm._s(_vm.albumRelationData.num) +
                  "次提醒"
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "record-content lotTable" }, [
            _c(
              "div",
              { staticClass: "table-view" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "color-black",
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      data: _vm.albumRelationData.list,
                      "element-loading-text": "Loading",
                      fit: "",
                      height: "90%",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _c("div", { staticClass: "tableEmpty" }, [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tableDiv" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "用户信息", "min-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "avatar-img" }, [
                                  _c("img", {
                                    attrs: { src: row.user.avatar, alt: "" },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "user-info flex-one ml10",
                                    staticStyle: { overflow: "hidden" },
                                  },
                                  [
                                    row.user.real_name
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.real_name)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(_vm._s(row.user.nickname)),
                                    ]),
                                    _vm._v(" "),
                                    row.user.phone
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.phone)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.user.mark
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.user.mark)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("UID:" + _vm._s(row.user.uid)),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "提醒类型",
                        "min-width": "100",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "color-gray" }, [
                                _vm._v(
                                  _vm._s(row.type == 11 ? "拍品" : "专场")
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "信息", "min-width": "140" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.type == 11 && row.product
                                ? _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", { staticClass: "avatar-img" }, [
                                        _c("img", {
                                          attrs: {
                                            src: row.product.image + "!120a",
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-info flex-one ml10",
                                          staticStyle: { overflow: "hidden" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "LOT" +
                                                _vm._s(row.product.lot) +
                                                " " +
                                                _vm._s(row.product.store_name)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : row.type == 12 && row.album
                                ? _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", { staticClass: "avatar-img" }, [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              row.album.album_image + "!120a",
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-info flex-one ml10",
                                          staticStyle: { overflow: "hidden" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(row.album.album_name)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "设置时间",
                        "min-width": "120",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [_vm._v(_vm._s(row.create_time))]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt20 flex justify-e" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "page-size": _vm.query.limit,
                        "current-page": _vm.query.page,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.otherTotal,
                      },
                      on: { "current-change": _vm.pageOtherChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.find");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _addUser = _interopRequireDefault(require("@/components/addUser.vue"));
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
var _product = require("@/api/product");
var _setting = require("@/api/setting");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "createoutorder",
  components: {
    AddUserCom: _addUser.default
  },
  data: function data() {
    return {
      loading: false,
      rules: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        out_warehouse_id: [{
          required: true,
          message: "请选择仓库ID",
          trigger: "change"
        }],
        manage_admin_id: [{
          required: false,
          message: "请选择业务负责人",
          trigger: "change"
        }],
        out_type: [{
          required: true,
          message: "请选择出库类型",
          trigger: "change"
        }]
      }, "out_warehouse_id", [{
        required: true,
        message: "请选择仓库ID",
        trigger: "change"
      }]), "manage_admin_id", [{
        required: false,
        message: "请选择业务负责人",
        trigger: "change"
      }]), "out_type", [{
        required: true,
        message: "请选择出库类型",
        trigger: "change"
      }]),
      formValidate: {
        out_warehouse_id: "",
        manage_admin_id: "",
        out_type: ""
      },
      timeVal: [],
      goodsData: [],
      selectFlag: false,
      tableFrom: {
        page: 1,
        limit: 20,
        cate_id: "",
        keyword: "",
        id: ""
      },
      tableData: {
        data: [],
        total: 0
      },
      merSelect: [],
      editFlag: false,
      num: 0,
      id: this.$route.query.id || "",
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        }
      },
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: "",
      storeArr: [],
      adminarr: [],
      goodsArr: [],
      goodsIdArr: [],
      BrandList: [],
      attrList: [],
      attr: [],
      orderno: '',
      mannagename: '',
      searchQuery: {},
      userList: [],
      removeList: [],
      usershList: [],
      addrList: []
    };
  },
  mounted: function mounted() {
    // if (this.id) {
    //   this.getGoodsList();
    // }
    // this.getDetail();
    // getOrderNo().then(res=>{
    //   this.orderno = res.data.order_no
    // })
    this.getstore();
    this.getadminList();
    // this.getBrandListApi();
    // getBaseInfo()
    //   .then(res => {
    //     this.label = res.data;
    //     this.mannagename = res.data.merchantAdmin.real_name;
    //     if(res.data.isStaff){
    //       this.formValidate.manage_admin_id = res.data.merchantAdmin.merchant_admin_id;
    //     }
    //   })
    //   .catch(({ message }) => {
    //     // 
    //   });
  },
  methods: {
    onUserChange: function onUserChange() {
      var _this = this;
      (0, _user.userAddressListApi)({
        uid: this.formValidate.express_user_name
      }).then(function (res) {
        console.log(res);
        _this.addrList = res.data.list;
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this2 = this;
      var params = {
        keyword: data,
        page: 1,
        size: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this2.usershList = res.data.list;
      });
    },
    createUser: function createUser() {
      this.$refs.userREf.adduser();
    },
    onUserAddre: function onUserAddre() {
      var _this3 = this;
      var row = this.addrList.find(function (item) {
        return item.detail == _this3.formValidate.express_user_address;
      });
      this.formValidate.express_user_phone = row.phone;
    },
    getInfoAction: function getInfoAction() {
      var _this4 = this;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(this.id).then(function (res) {
        // console.log('1----0',res);
        _this4.searchUserAction(res.data.express_user_name);
        _this4.formValidate.out_warehouse_id = res.data.outWarehouse.warehouse_id;
        _this4.$set(_this4.formValidate, 'remark', res.data.remark);
        _this4.$set(_this4.formValidate, 'out_type', res.data.out_type);
        _this4.$set(_this4.formValidate, 'out_mode', res.data.out_mode);
        _this4.$set(_this4.formValidate, 'express_user_name', Number(res.data.express_user_name));
        _this4.$set(_this4.formValidate, 'express_user_phone', res.data.express_user_phone);
        _this4.$set(_this4.formValidate, 'express_user_address', res.data.express_user_address);
        _this4.selectActiveGoods();
        res.data.warehouseOrderProduct.forEach(function (m) {
          m = (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, m.erpProduct), m), {}, {
            id: m.erpProduct.id,
            label_ids: m.erpProduct.label_ids ? m.erpProduct.label_ids.split(',').map(function (item) {
              return Number(item);
            }) : []
          });
          m.edit = true;
          _this4.saleGoods(m, "goods", "");
        });
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    close: function close() {
      this.$router.back();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 品牌筛选；
    getBrandListApi: function getBrandListApi() {
      var _this5 = this;
      (0, _product.brandListApi)().then(function (res) {
        _this5.BrandList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getadminList: function getadminList() {
      var _this6 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this6.adminarr = res.data.list;
        _this6.userList = res.data.list.map(function (item) {
          return {
            label: item.real_name,
            value: item.merchant_admin_id
          };
        });
      });
    },
    getstore: function getstore() {
      var _this7 = this;
      (0, _product.getStoreList)().then(function (res) {
        _this7.storeArr = res.data.list;
        if (_this7.$route.query.id) {
          _this7.formValidate.id = _this7.$route.query.id;
          _this7.getInfoAction();
        } else if (res.data.list[0]) {
          _this7.searchUserAction('');
          _this7.formValidate.out_warehouse_id = res.data.list[0].warehouse_id;
          _this7.selectActiveGoods();
        }
      });
    },
    submitForm: function submitForm(formName) {
      var _this8 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // if(!this.goodsIdArr.length){
          //    return this.$message.error('请选择货品')
          // }
          _this8.loading = true;
          var goodsarr = _this8.goodsArr;
          if (!goodsarr.length) {
            return _this8.$message.error('请选择货品');
          }
          // this.formValidate.productData = goodsarr;
          var json = {};
          json.out_id = _this8.formValidate.out_warehouse_id;
          json.warehouseProduct = goodsarr.map(function (item) {
            return {
              erp_product_id: item.id,
              product_no: item.product_no,
              shelf_id: item.shelf_id,
              image: item.image,
              product_name: item.product_name
            };
          });
          json.out_type = _this8.formValidate.out_type;
          json.remark = _this8.formValidate.remark;
          json.out_mode = _this8.formValidate.out_mode;
          json.express_user_name = _this8.formValidate.express_user_name;
          json.express_user_phone = _this8.formValidate.express_user_phone;
          json.express_user_address = _this8.formValidate.express_user_address;
          // json.out_mode = 'express';
          // this.formValidate.out_warehouse_id = this.formValidate.warehouse_id;
          if (_this8.id) {
            (0, _warehouse.updateOutApi)(_this8.id, json).then(function (res) {
              _this8.loading = false;
              _this8.$message.success("保存成功");
              console.log(_this8.refser);
              if (_this8.refser) {
                _this8.$emit('savesuccess', res.message);
              } else {
                _this8.$router.back();
              }
            }).catch(function (res) {
              _this8.loading = false;
              // this.$message.error(res.message);
            });
            return;
          } else {
            (0, _warehouse.createoutOrderApiV2)(json).then(function (res) {
              _this8.loading = false;
              if (res.status == 200) {
                _this8.$message.success("保存成功");
                _this8.$router.back();
              }
            }).catch(function (res) {
              _this8.loading = false;
              // this.$message.error(res.message);
            });
          }
        } else {
          return false;
        }
      });
    },
    getDetail: function getDetail() {
      if (!this.id) {
        return;
      }
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      //   this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.formValidate.stime = e[0];
      this.formValidate.etime = e[1];
    },
    getList: function getList() {
      var _this9 = this;
      this.tableFrom.warehouse_id = this.formValidate.out_warehouse_id;
      this.tableFrom.product_warehouse_status = 2;
      (0, _basic.getgoodsListV2Api)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.selected = false;
        });
        if (_this9.tableFrom.page == 1 && _this9.removeList.length > 0) {
          _this9.tableData.data = [].concat((0, _toConsumableArray2.default)(_this9.removeList), (0, _toConsumableArray2.default)(res.data.list));
        } else _this9.tableData.data = res.data.list;
        _this9.tableData.total = res.data.count;
        _this9.listLoading = false;
      }).catch(function (res) {
        _this9.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    selectInventGoods: function selectInventGoods() {
      this.selectFlag = true;
      this.getList();
      this.selectInventFlag = true;
    },
    selectActiveGoods: function selectActiveGoods() {
      if (!this.formValidate.out_warehouse_id) {
        this.$message.error('请选择仓库');
        return;
      }
      this.selectFlag = true;
      this.selectInventFlag = false;
      this.getList();
    },
    saleGoods: function saleGoods(row, index) {
      if (this.goodsIdArr.indexOf(row.id) > -1) {
        return;
      }
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      this.goodsArr.push(row);
      this.goodsIdArr.push(row.id);
    },
    saleGoodsAll: function saleGoodsAll() {
      var _this10 = this;
      this.tableData.data.forEach(function (m, i) {
        _this10.saleGoods(m, i);
      });
    },
    closeSelect: function closeSelect() {
      this.selectFlag = false;
    },
    delGoods: function delGoods(index, row, cindex) {
      if (row.edit) {
        this.tableData.data.unshift(row);
        this.removeList.push(row);
      }
      var nindex = this.goodsIdArr.indexOf(row.id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
    },
    cancelSelect: function cancelSelect(index, row) {
      row.selected = false;
      var nindex = this.goodsIdArr.indexOf(row.id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
      return;
      row.selected = false;
      this.tableData.data.splice(index, 1);
      this.tableData.data.push(row);
    }
  }
};
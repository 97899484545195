"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _index = require("@/utils/index");
var _contract = require("@/api/contract");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      total_rmb: '',
      result: [],
      isNo: true
    };
  },
  methods: {
    bindPrintorder: function bindPrintorder() {
      //console.log("是不是修改的这里啊");
      var that = this;
      (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page {margin:5mm 10mm 12mm}",
        onPrintDialogClose: function onPrintDialogClose() {
          console.log('关闭');
        }
      }, "onPrintDialogClose", function onPrintDialogClose() {
        console.log('pdf');
        (0, _order.batchCreate)({
          order_ids: that.collectMoney.order_ids,
          order_type: 'erp_v2_entrust_check_auction'
        });
        that.collectMoney.show = false;
        that.$emit('load');
      }), "onPdfOpen", function onPdfOpen() {
        console.log('pdf1');
      }));
    },
    loadData: function loadData(ids, uid, source) {
      var _this = this;
      this.isNo = true;
      this.source = source;
      (0, _contract.printProductOrderApi)({
        check_auction_ids: ids
      }).then(function (res) {
        // console.log(res.data.checkAuctionData);
        // m.pmAuction.now_price
        var total = 0;
        var obj = {
          succ: 0,
          err: 0
        };
        var configPrice = JSON.parse(JSON.stringify(res.data.checkAuctionData[0].price_data));
        configPrice.forEach(function (item) {
          item.forEach(function (ele) {
            ele.deduction_amount = 0;
          });
        });
        res.data.checkAuctionData.forEach(function (item) {
          total += item.pmAuction.now_price ? Number(item.pmAuction.now_price) : 0;
          if (item.auction_status <= 4) {
            obj.err += 1;
          } else {
            obj.succ += 1;
          }
          item.price_data.forEach(function (a, i) {
            a.forEach(function (ele, index) {
              if (configPrice[i][index]) configPrice[i][index].deduction_amount += ele.deduction_amount ? ele.deduction_amount : 0;
            });
          });
        });
        // res.data.checkAuctionData = [...res.data.checkAuctionData,...res.data.checkAuctionData];
        res.data.configPrice = configPrice;
        res.data.obj = obj;
        res.data.nowTotal = total;
        _this.collectMoney = {
          order_ids: ids,
          data: res.data,
          show: true
        };
        _this.$nextTick(function () {
          // 赋值后马上更新
          _this.compendHeight();
        });
      }).catch(function () {});
    },
    compendHeight: function compendHeight() {
      var num = 0;
      var heightAll = 0;
      var arr = [];
      var dom = document.getElementsByClassName('calcul');
      for (var i = 0; i < dom.length; i++) {
        heightAll += Number(window.getComputedStyle(dom[i]).height.replace('px', ''));
        if (num == 0 && heightAll > 1020) {
          arr.push(i);
          num += 1;
        } else if (num > 0 && heightAll > 1200 * num + 1020) {
          arr.push(i);
          num += 1;
        }
        // else if (num == 0 && heightAll+){

        // }
      }
      ;
      // this.collectMoney.data.checkAuctionData
      this.result = this.sliceArrayIntoChunks(this.collectMoney.data.checkAuctionData, arr);
      this.isNo = false;
    },
    sliceArrayIntoChunks: function sliceArrayIntoChunks(array, indexes) {
      var arr = [];
      var len = array.length;
      var ind = 0;
      indexes.forEach(function (item, index) {
        arr.push(array.slice(ind, item));
        ind = item;
      });
      arr.push(array.slice(indexes[indexes.length - 1], len));
      return arr;
    },
    delAction: function delAction(i) {
      this.collectMoney.data.auctionData.splice(i, 1);
      this.getCNPrice();
    },
    getCNPrice: function getCNPrice() {
      var _this2 = this;
      var mer_id = localStorage.getItem('mer_id');
      var total_price = 0;
      this.collectMoney.data.auctionData.forEach(function (m) {
        total_price += m.should_pay_price;
      });
      this.total_price = total_price;
      (0, _contract.numberToRmbApi)({
        number: this.total_price,
        mer_id: mer_id
      }).then(function (res) {
        _this2.total_rmb = res.data.rmb;
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      }
    };
  },
  mounted: function mounted() {

    // 监听 beforeprint 事件  
  },
  methods: {
    bindPrintorder: function bindPrintorder() {
      //console.log("是不是修改的这里啊");
      var that = this;
      (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page { margin: 5mm 10mm 12mm;size: auto; } .paging{page-break-after: always;}",
        onPrintDialogClose: function onPrintDialogClose() {
          console.log('关闭');
        }
      }, "onPrintDialogClose", function onPrintDialogClose() {
        console.log('pdf');
        (0, _order.batchCreate)({
          order_ids: that.collectMoney.order_ids,
          order_type: 'order'
        });
        that.collectMoney.show = false;
        that.$emit('load');
      }), "onPdfOpen", function onPdfOpen() {
        console.log('pdf1');
      }));
    },
    getNewArray: function getNewArray(array) {
      var newArray = [];
      if (array.length > 20) {
        newArray.push(array.slice(0, 20));
        var ffarr = array.slice(20, array.length);
        var aaa = this.getSplArray(ffarr, 26);
        // console.log(aaa);
        aaa.forEach(function (item) {
          newArray.push(item);
        });
      } else newArray.push(array);
      return newArray;
    },
    getSplArray: function getSplArray(array, subGroupLength) {
      // array 代表一个数组
      // subGroupLength 代表数组里面的数组的length值
      var index = 0;
      var newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    loadData: function loadData(ids, uid, source) {
      var _this = this;
      this.source = source;
      (0, _order.getAuctionOrderBillInfo)({
        order_ids: ids,
        uid: uid
      }).then(function (res) {
        // console.log(res.data);
        var total_price = 0;
        res.data.orderList.forEach(function (m) {
          total_price += m.product_price;
        });
        var orderLength = res.data.orderList.length;
        res.data.orderList = _this.getNewArray(res.data.orderList);
        _this.total_price = total_price;
        _this.collectMoney = {
          order_ids: ids,
          data: res.data,
          show: true,
          orderLength: orderLength
        };
      }).catch(function () {});
    }
  }
};
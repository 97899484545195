"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _system = require("@/api/system");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      settlementRatio: ''
    };
  },
  methods: {
    bindPrintorder: function bindPrintorder() {
      var that = this;
      (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page {margin:0 10mm}",
        onPrintDialogClose: function onPrintDialogClose() {
          console.log('关闭');
        }
      }, "onPrintDialogClose", function onPrintDialogClose() {
        console.log('pdf');
        (0, _order.batchCreate)({
          order_ids: that.collectMoney.order_ids,
          order_type: 'order'
        });
        that.collectMoney.show = false;
        that.$emit('load');
      }), "onPdfOpen", function onPdfOpen() {
        console.log('pdf1');
      }));
    },
    loadData: function loadData(ids, uid, source) {
      var _this = this;
      this.source = source;
      (0, _system.getConfigClassKeys)('auction_rule_set').then(function (res) {
        if (res.data.settlementRatio === '') {
          _this.settlementRatio = '0';
        } else {
          _this.settlementRatio = res.data.settlementRatio;
        }
      });
      (0, _order.getAuctionOrderBillInfo)({
        order_ids: ids,
        uid: uid
      }).then(function (res) {
        console.log(res.data);
        var total_price = 0;
        res.data.orderList.forEach(function (m) {
          total_price += m.product_price;
        });
        var len = res.data.orderList.length;
        for (var i = 0; i < 5 - len; i++) {
          console.log(i);
          res.data.orderList.push({});
        }
        _this.total_price = total_price;
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        res.data.newtime = "".concat(year, "-").concat(month < 10 ? '0' + month : month, "-").concat(day < 10 ? '0' + day : day);
        _this.collectMoney = {
          data: res.data,
          show: true
        };
      }).catch(function () {});
    }
  }
};
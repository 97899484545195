var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _vm.collectMoney.show
        ? _c(
            "div",
            { attrs: { id: "printorder" } },
            [
              _c("div", { staticClass: "detail-box" }, [
                _c("div", { staticClass: "detail-left" }, [
                  _c("div", { staticClass: "detail-left-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.collectMoney.data.merConfig.company_name) +
                        " | " +
                        _vm._s(
                          _vm.source == "send" ? "提货单" : "收款结算账单"
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "detail-left-desc",
                      staticStyle: {
                        "font-size": "14px",
                        "font-family": "'printEN'",
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.collectMoney.data.merConfig.company_english_name
                          ) +
                          "  " +
                          _vm._s(
                            _vm.source == "send"
                              ? "Bill of Lading"
                              : "Collection settlement bill"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-abso" }, [
                    _vm.collectMoney.data.recharge_order
                      ? _c("div", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v(
                            "收款时间：" +
                              _vm._s(
                                _vm.collectMoney.data.recharge_order.create_time
                              )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "font-size": "14px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.collectMoney.data.recharge_order ||
                            _vm.collectMoney.data.paid == 1
                            ? "已结算"
                            : "未结算"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "secTit" }, [
                _vm._v(
                  "您好！感谢您对我公司的大力支持，希望您能一如既往地支持我公司，谢谢！"
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                  },
                },
                [
                  _vm._v(
                    "Thanks for your support to our company, we beg your understanding and also hope you can support us as usual!"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-title",
                  staticStyle: { "margin-top": "40px" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.collectMoney.data.venue_info.title) + " "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _vm.collectMoney.data.userVerifyInfo
                    ? _c("div", [
                        _vm._v("买受人姓名 BUYER'S NAME "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              "font-size": "16px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.collectMoney.data.userVerifyInfo.real_name
                              )
                            ),
                          ]
                        ),
                      ])
                    : _vm.collectMoney.data.userInfo
                    ? _c("div", [
                        _vm._v("买受人姓名 BUYER'S NAME "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              "font-size": "16px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.collectMoney.data.userInfo.nickname)
                            ),
                          ]
                        ),
                      ])
                    : _c("div", [_vm._v("买受人姓名 BUYER'S NAME")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("号牌 PADDLE NO. "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.collectMoney.data.numberPlate
                              ? _vm.collectMoney.data.numberPlate.number
                              : ""
                          )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("交易日期 DATE OF SALE "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.collectMoney.data.order_time))]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.collectMoney.data.delivery_type != 2
                ? _c(
                    "div",
                    {
                      staticClass: "detail-list",
                      staticStyle: { "margin-top": "5px" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.collectMoney.data.address_info.user_address
                          ) +
                            " " +
                            _vm._s(
                              _vm.collectMoney.data.address_info.user_phone
                            ) +
                            " " +
                            _vm._s(_vm.collectMoney.data.address_info.real_name)
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.collectMoney.data.orderList, function (item, ind) {
                return _c(
                  "div",
                  {
                    key: ind,
                    staticClass: "ptd-box",
                    style:
                      ind < _vm.collectMoney.data.orderList.length - 1
                        ? "page-break-after: always;"
                        : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ptd",
                        staticStyle: { "font-weight": "bold" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [
                            _c("span", [_vm._v("图录号")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("LOT NO.")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [_vm._v("拍品名称")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("DESCRIPTION")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [_vm._v("落槌价")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("HAMMER PRICE")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [_vm._v("佣金")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("COMMISSION")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [_vm._v("其他费用")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("OTHER")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [_vm._v("金额")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("SUBTOTAL")]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(item, function (m, i) {
                      return _c(
                        "div",
                        { key: "order" + i, staticClass: "ptd" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [_vm._v(_vm._s(m.auction_info.lot))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("div", [
                                _vm._v(_vm._s(m.auction_info.store_name)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(m.product_price, 2)
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        m.commission_price,
                                        2
                                      )
                                    )
                                  ),
                                  m.commission_rate
                                    ? [
                                        _vm._v(
                                          " / " +
                                            _vm._s(m.commission_rate) +
                                            "%"
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              m.total_postage && m.delivery_type == 1
                                ? _c("div", [
                                    _vm._v(
                                      "运费：" +
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.total_postage,
                                            2
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              m.admin_discount_price
                                ? _c("div", [
                                    _vm._v(
                                      "优惠：-" +
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.admin_discount_price,
                                            2
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(m.payable_price, 2)
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ptd-box",
                  staticStyle: { "margin-top": "0", "border-top": "none" },
                },
                [
                  _c("div", { staticClass: "ptd" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        staticStyle: { flex: "1", "flex-direction": "column" },
                      },
                      [
                        _c("span", [_vm._v("合计")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "font-family": "'printEN'" } },
                          [_vm._v("TOTAL")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.collectMoney.orderLength) + "件"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(_vm.formatPriceNumber(_vm.total_price, 2))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              _vm.collectMoney.data.total_commission_price,
                              2
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm.collectMoney.data.total_postage
                          ? _c("div", [
                              _vm._v(
                                "运费：" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data.total_postage,
                                      2
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.collectMoney.data.total_admin_discount_price
                          ? _c("div", [
                              _vm._v(
                                "优惠：-" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .total_admin_discount_price,
                                      2
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              _vm.collectMoney.data.total_payable_price,
                              2
                            )
                          )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.collectMoney.data.total_admin_discount_price == 0
                    ? _c("div", { staticClass: "ptd" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: {
                              flex: "3",
                              "margin-left": "-3px",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data.recharge_order ||
                                      _vm.collectMoney.data.paid == 1
                                      ? "实付金额"
                                      : "待结算金额"
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-family": "'printEN'",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data.recharge_order ||
                                      _vm.collectMoney.data.paid == 1
                                      ? "ACTUAL PAYMENT AMOUNT"
                                      : "PENDING SETTLEMENT AMOUNT"
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: { flex: "8", "font-weight": "bold" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.collectMoney.data.total_pay_price == 0
                                  ? ""
                                  : _vm.formatPriceNumber(
                                      _vm.collectMoney.data.total_pay_price,
                                      2
                                    )
                              )
                            ),
                          ]
                        ),
                      ])
                    : _c("div", { staticClass: "ptd" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: {
                              flex: "3",
                              "margin-left": "-10px",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  "padding-left": "10px",
                                },
                              },
                              [_vm._v("折扣金额")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-family": "'printEN'",
                                  "text-align": "left",
                                  "padding-left": "10px",
                                },
                              },
                              [_vm._v("DISCOUNT AMOUNT")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { flex: "8", display: "flex" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { width: "25%" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data
                                      .total_admin_discount_price
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  width: "calc(50% + 2px)",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.collectMoney.data.recharge_order ||
                                          _vm.collectMoney.data.paid == 1
                                          ? "实付金额"
                                          : "待结算金额"
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-family": "'printEN'",
                                      "text-align": "left",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.collectMoney.data.recharge_order ||
                                          _vm.collectMoney.data.paid == 1
                                          ? "ACTUAL PAYMENT AMOUNT"
                                          : "PENDING SETTLEMENT AMOUNT"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  flex: "1",
                                  "font-weight": "bold",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data.total_pay_price == 0
                                      ? ""
                                      : _vm.formatPriceNumber(
                                          _vm.collectMoney.data.total_pay_price
                                        )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ptd" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        staticStyle: {
                          flex: "3",
                          "margin-left": "-3px",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "text-align": "left" } }, [
                          _vm._v("人民币（大写）"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "'printEN'",
                              "text-align": "left",
                            },
                          },
                          [_vm._v("CAPITAL IN RMB")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        staticStyle: { flex: "8", "font-weight": "bold" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.collectMoney.data.total_pay_price == 0
                              ? ""
                              : _vm.collectMoney.data.total_pay_price_rmb
                          )
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "secTit",
                  staticStyle: { "margin-top": "30px" },
                },
                [
                  _vm._v(
                    "* 本人已验收全部拍品，确认无误并毫无损伤，本人签字表示同意。"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                  },
                },
                [
                  _vm._v(
                    "I confirm that all the items mentioned above without any damage after acceptance inspection, and I sign and agree to take delivery of the items."
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "print-line",
                staticStyle: { "margin-top": "30px" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "detail-sign" }, [
                _vm._v("提货人签字"),
                _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                  _vm._v("（SIGNATURE）"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "print-line" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "30px" },
                },
                [
                  _c("div", [
                    _vm._v(
                      "开户名称：" +
                        _vm._s(
                          _vm.collectMoney.data.merConfig
                            ? _vm.collectMoney.data.merConfig.bank_name
                            : ""
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "开户行：" +
                        _vm._s(
                          _vm.collectMoney.data.merConfig
                            ? _vm.collectMoney.data.merConfig.bank
                            : ""
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "账号：" +
                        _vm._s(
                          _vm.collectMoney.data.merConfig
                            ? _vm.collectMoney.data.merConfig.bank_number
                            : ""
                        )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _c("div", [
                    _vm._v(
                      "公司地址：" +
                        _vm._s(
                          _vm.collectMoney.data.merConfig
                            ? _vm.collectMoney.data.merConfig.company_address
                            : ""
                        )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _c("div", [
                    _vm._v(
                      "单据编号：" +
                        _vm._s(
                          _vm.collectMoney.data.recharge_order
                            ? _vm.collectMoney.data.recharge_order.out_trade_no
                            : ""
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "收款人：" + _vm._s(_vm.collectMoney.data.admin_name)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v("复核人：")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("单位盖章：")]),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
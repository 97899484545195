var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "printorder" } },
    [
      _c("div", { staticClass: "detail-box" }, [
        _c("div", { staticClass: "detail-left" }, [
          _c("div", { staticClass: "detail-left-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.merData.company_name) +
                " | 拍品入库清单\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "detail-left-desc",
              staticStyle: { "font-size": "14px" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.merData.company_english_name) +
                  "  AUCTION INBOUND ORDER\n    "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "detail-right" }, [
          _c("img", {
            staticStyle: { width: "200px" },
            attrs: {
              src: "data:image/png;base64," + _vm.detailjson.bar_code_image,
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "detail-code" }, [
            _vm._v(_vm._s(_vm.detailjson.order_no)),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.isNo
        ? _c(
            "div",
            { staticClass: "ptd-box" },
            [
              _c(
                "div",
                { staticClass: "ptd", staticStyle: { "font-weight": "bold" } },
                _vm._l(_vm.showFiled, function (item, index) {
                  return _c("div", { key: index, staticClass: "ptd-title" }, [
                    _c("span", [_vm._v(_vm._s(item.origin_name))]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'printEN'" } },
                      [_vm._v(_vm._s(_vm._f("englis")(item.name)))]
                    ),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm._l(
                _vm.detailjson.warehouseOrderProduct,
                function (item, index) {
                  return _c(
                    "div",
                    { key: "order" + index, staticClass: "ptd calcul" },
                    _vm._l(_vm.showFiled, function (ele, ind) {
                      return _c(
                        "div",
                        {
                          key: "order" + index + "-" + ind,
                          staticClass: "ptd-body",
                        },
                        [
                          ele.field == "image"
                            ? _c(
                                "span",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("img", {
                                    staticStyle: { width: "calc(100% - 30px)" },
                                    attrs: {
                                      src: item.erpProduct[ele.field],
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : Number(ele.field) < 1000
                            ? _c("span", [
                                item.erpProduct.attribute
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.erpProduct.attribute[ele.field]
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : ele.field == "label"
                            ? _c("span", [
                                item.erpProduct[ele.field]
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.erpProduct[ele.field].join(",")
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(item.erpProduct[ele.field])),
                              ]),
                        ]
                      )
                    }),
                    0
                  )
                }
              ),
            ],
            2
          )
        : _vm._l(_vm.result, function (abc, ind) {
            return _c(
              "div",
              {
                key: ind,
                staticClass: "ptd-box",
                style:
                  ind < _vm.result.length - 1
                    ? "page-break-after: always;"
                    : "",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ptd",
                    staticStyle: { "font-weight": "bold" },
                  },
                  _vm._l(_vm.showFiled, function (item, index) {
                    return _c("div", { key: index, staticClass: "ptd-title" }, [
                      _c("span", [_vm._v(_vm._s(item.origin_name))]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'printEN'" } },
                        [_vm._v(_vm._s(_vm._f("englis")(item.name)))]
                      ),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _vm._l(abc, function (item, index) {
                  return _c(
                    "div",
                    { key: "order" + index, staticClass: "ptd" },
                    _vm._l(_vm.showFiled, function (ele, ind) {
                      return _c(
                        "div",
                        {
                          key: "order" + index + "-" + ind,
                          staticClass: "ptd-body",
                        },
                        [
                          ele.field == "image"
                            ? _c(
                                "span",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("img", {
                                    staticStyle: { width: "calc(100% - 30px)" },
                                    attrs: {
                                      src: item.erpProduct[ele.field],
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : Number(ele.field) < 1000
                            ? _c("span", [
                                item.erpProduct.attribute
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.erpProduct.attribute[ele.field]
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : ele.field == "label"
                            ? _c("span", [
                                item.erpProduct[ele.field]
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.erpProduct[ele.field].join(",")
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(item.erpProduct[ele.field])),
                              ]),
                        ]
                      )
                    }),
                    0
                  )
                }),
              ],
              2
            )
          }),
      _vm._v(" "),
      _vm.detailjson.type
        ? [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "20px 0 10px",
                  "font-size": "16px",
                  "font-weight": "bold",
                  color: "#000",
                },
              },
              [_vm._v("入库凭证")]
            ),
            _vm._v(" "),
            _vm.detailjson.enter_image
              ? _c(
                  "div",
                  { staticClass: "flex", staticStyle: { "flex-wrap": "wrap" } },
                  [
                    _vm._l(
                      _vm.detailjson.enterImageList,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "upLoadPicBox flex align-items-c flex-wrap",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pictrue mr10 mb10" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: item + "!120a",
                                    "preview-src-list":
                                      _vm.detailjson.enterImageList,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "upLoadPicBox flex align-items-c flex-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap("1")
                          },
                        },
                      },
                      [_vm._m(0)]
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isShow && !_vm.detailjson.type
        ? _c("div", { staticClass: "detail-sign" }, [
            _vm._v("送货人签字"),
            _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
              _vm._v("（SIGNATURE）"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isShow && !_vm.detailjson.type
        ? _c("div", { staticClass: "print-line" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "detail-list",
          staticStyle: {
            "margin-top": "38px",
            "font-weight": "bold",
            "font-size": "16px",
          },
        },
        [
          _c(
            "div",
            [
              _vm._v("\n    制单人：\n    "),
              _vm.detailjson.createAdmin
                ? [_vm._v(_vm._s(_vm.detailjson.createAdmin.real_name))]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", [_vm._v("审批人：")]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v("\n    入库人：\n    "),
              _vm.detailjson.enterAdmin
                ? [_vm._v(_vm._s(_vm.detailjson.enterAdmin.real_name))]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v("\n    入库库房：\n    "),
              _vm.detailjson.enterWarehouse
                ? [_vm._v(_vm._s(_vm.detailjson.enterWarehouse.name))]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "detail-list" }, [
        _c("div", [_vm._v("单据编号：" + _vm._s(_vm.detailjson.order_no))]),
        _vm._v(" "),
        _c("div", [_vm._v("制单时间：" + _vm._s(_vm.detailjson.create_time))]),
        _vm._v(" "),
        _c("div", [
          _vm._v("入库类型：" + _vm._s(_vm.detailjson.enterTypeText)),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upLoad mb10" }, [
      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm ft14 color-black",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("升级内容")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "w300",
                  attrs: { type: "textarea", controls: false },
                  model: {
                    value: _vm.ruleForm.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "content", $$v)
                    },
                    expression: "ruleForm.content",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("安卓版本")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticClass: "w100",
                  attrs: { controls: false },
                  model: {
                    value: _vm.ruleForm.androidversion,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "androidversion", $$v)
                    },
                    expression: "ruleForm.androidversion",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ft14 ml10 fWeight500" }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("安卓升级地址")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "w300",
                  attrs: { controls: false },
                  model: {
                    value: _vm.ruleForm.androidUpdateUrl,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "androidUpdateUrl", $$v)
                    },
                    expression: "ruleForm.androidUpdateUrl",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("IOS版本")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticClass: "w100",
                  attrs: { controls: false },
                  model: {
                    value: _vm.ruleForm.iosversion,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "iosversion", $$v)
                    },
                    expression: "ruleForm.iosversion",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ft14 ml10 fWeight500" }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", { staticClass: "t" }, [_vm._v("IOS升级地址")]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "w300",
                  attrs: { controls: false },
                  model: {
                    value: _vm.ruleForm.iosUpdateUrl,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "iosUpdateUrl", $$v)
                    },
                    expression: "ruleForm.iosUpdateUrl",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "big",
                    attrs: { type: "primary" },
                    on: { click: _vm.saveStartPage },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var auctionRouter = {
  path: "".concat(_settings.roterPre, "/pageManagement/auction"),
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
  meta: {
    title: '拍场管理',
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'meeting',
    name: 'meeting',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/meeting'));
      });
    },
    meta: {
      title: '拍卖会',
      noCache: true,
      permissionKey: '/pageManagement/auction/meeting'
    }
  }, {
    path: 'meeting/meetingDetail',
    name: 'meetingDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/detailView/meetingDetail'));
      });
    },
    meta: {
      title: '拍卖会详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    }
  }, {
    path: 'antique',
    name: 'antique',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/antique'));
      });
    },
    meta: {
      title: '文物局报表',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    }
  }, {
    path: 'meetingData',
    name: 'meetingData',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/pageManagement/auction/meetingData/album"),
    meta: {
      title: '拍卖会',
      noCache: true
    },
    children: [{
      path: 'album',
      name: 'album',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/album'));
        });
      },
      meta: {
        title: '专场管理',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/pageManagement/auction/meetingData/album'
      }
    }, {
      path: 'auction',
      name: 'auction',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/auction'));
        });
      },
      meta: {
        title: '拍品管理',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/pageManagement/auction/meetingData/auction'
      }
    }, {
      path: 'numberPlate',
      name: 'numberPlate',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/numberPlate'));
        });
      },
      meta: {
        title: '号牌管理',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/pageManagement/auction/meetingData/numberPlate'
      }
    }, {
      path: 'realTime',
      name: 'realTime',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/realTimeData'));
        });
      },
      meta: {
        title: '场控监测',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/pageManagement/auction/meetingData/realTime'
      }
    }, {
      path: 'entrust',
      name: 'entrust',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/entrust'));
        });
      },
      meta: {
        title: '委托',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/pageManagement/auction/meetingData/entrust'
      }
    }, {
      path: 'Live',
      name: 'albumLive',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/live'));
        });
      },
      meta: {
        title: '直播',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/pageManagement/auction/meetingData/Live'
      }
    }, {
      path: 'LiveOne',
      name: 'albumLiveOne',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/liveOne'));
        });
      },
      meta: {
        title: '投屏',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/pageManagement/auction/meetingData/LiveOne'
      }
    }, {
      path: 'LiveTwo',
      name: 'albumLiveTwo',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/liveTwo'));
        });
      },
      meta: {
        title: '出价控制台',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/auctionConsole'
      }
    }, {
      path: 'data',
      name: 'meetingDataStatistics',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/data'));
        });
      },
      meta: {
        title: '统计报表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/pageManagement/auction/meetingData/data'
      }
    }, {
      path: 'helpAuction',
      name: 'meetingHelpAuction',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/helpAuction'));
        });
      },
      meta: {
        title: '云代拍',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/pageManagement/auction/meetingData/helpAuction'
      }
    }]
  }, {
    path: 'promotionMaterials',
    name: 'promotionMaterials',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/promotionMaterials'));
      });
    },
    meta: {
      title: '推广素材',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    }
  }, {
    path: 'marketMatrix',
    name: 'marketMatrix',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/index'));
      });
    },
    meta: {
      title: '营销矩阵',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
    },
    redirect: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix/materialProduction"),
    children: [{
      path: 'materialProduction',
      name: 'materialProduction',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/materialProduction'));
        });
      },
      meta: {
        title: '素材制作',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
      }
    }, {
      path: 'channel',
      name: 'channel',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/channel'));
        });
      },
      meta: {
        title: '推广渠道',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
      }
    }, {
      path: 'promotionPlan',
      name: 'promotionPlan',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/promotionPlan'));
        });
      },
      meta: {
        title: '推广计划',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
      }
    }, {
      path: 'taskList',
      name: 'taskList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/taskList'));
        });
      },
      meta: {
        title: '任务列表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
      }
    }]
  }, {
    path: 'staffConfig',
    name: 'staffConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/staffConfig'));
      });
    },
    meta: {
      title: '数字员工配置',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
    }
  }, {
    path: 'chanDetails',
    name: 'chanDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/chanDetails'));
      });
    },
    meta: {
      title: '账号详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
    }
  }, {
    path: 'videoGenerateLog',
    name: 'videoGenerateLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/videoLog'));
      });
    },
    meta: {
      title: '视频生成纪录',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
    }
  }, {
    path: 'recovery',
    name: 'recovery',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/recovery'));
      });
    },
    meta: {
      title: '回收站',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
    }
  }, {
    path: 'imageText',
    name: 'imageText',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/imageText'));
      });
    },
    meta: {
      title: '图文素材',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
    }
  }, {
    path: 'wechatTemmplate',
    name: 'wechatTemmplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/aiMarketing/wechatTemmplate'));
      });
    },
    meta: {
      title: '微信模板',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/marketMatrix")
    }
  }, {
    path: 'handleCode',
    name: 'handleCode',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/handleCode'));
      });
    },
    meta: {
      title: '现场办牌',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    }
  }, {
    path: 'analysis',
    name: 'analysis',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/analysis'));
      });
    },
    meta: {
      title: '买家分析',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
      permissionKey: '/pageManagement/auction/analysis'
    }
  }, {
    path: 'helpAuction',
    name: 'meetingHelpAuction',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/helpAuction'));
      });
    },
    meta: {
      title: '云代拍',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
      permissionKey: '/pageManagement/auction/helpAuction'
    }
  },
  // {
  //   path: 'spread',
  //   name: 'spread',
  //   component: () => import('@/views/auction/meetingData/spread'),
  //   meta: { title: '营销推广', noCache: true, activeMenu: `${roterPre}/pageManagement/auction/meeting` }
  // },
  {
    path: 'album_data',
    name: 'albumDataStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/albumData'));
      });
    },
    meta: {
      title: '统计报表',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    }
  }, {
    path: 'meetingData/albumDetail',
    name: 'albumDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/detailView/albumDetail'));
      });
    },
    meta: {
      title: '专场详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    }
  }, {
    path: 'meetingData/entrustDetail',
    name: 'entrustDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/detailView/entrustDetail'));
      });
    },
    meta: {
      title: '委托详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    }
  }, {
    path: 'meetingData/auctionDetail',
    name: 'auctionDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/detailView/auctionDetail'));
      });
    },
    meta: {
      title: '拍品详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    }
  }, {
    path: 'collect',
    name: 'auction_collect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/collect/list'));
      });
    },
    meta: {
      title: '拍品征集',
      noCache: true,
      permissionKey: '/pageManagement/auction/collect'
    }
  }, {
    path: 'collect_detail',
    name: 'auction_collect_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/collect/detail'));
      });
    },
    meta: {
      title: '征集详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/collect"),
      permissionKey: '/pageManagement/auction/collect'
    }
  }, {
    path: 'collect_auction',
    name: 'auction_collect_auction',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/collect/auction'));
      });
    },
    meta: {
      title: '送拍列表',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/collect"),
      permissionKey: '/pageManagement/auction/collect'
    }
  }]
};
var _default = exports.default = auctionRouter;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: (_vm.id ? "修改" : "创建") + "入库单", "has-back": "" },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "150px" },
              attrs: { placeholder: "库房", clearable: "", filterable: "" },
              on: { change: _vm.pushshelf },
              model: {
                value: _vm.formValidate.warehouse_id,
                callback: function ($$v) {
                  _vm.$set(_vm.formValidate, "warehouse_id", $$v)
                },
                expression: "formValidate.warehouse_id",
              },
            },
            _vm._l(_vm.storeArr, function (item) {
              return _c("el-option", {
                key: item.warehouse_id,
                attrs: { label: item.name, value: item.warehouse_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "150px" },
              attrs: { placeholder: "入库类型", clearable: "" },
              model: {
                value: _vm.formValidate.enter_type,
                callback: function ($$v) {
                  _vm.$set(_vm.formValidate, "enter_type", $$v)
                },
                expression: "formValidate.enter_type",
              },
            },
            [
              _c("el-option", {
                attrs: { label: "委托入库", value: "enter_warehouse_entrust" },
              }),
              _vm._v(" "),
              _c("el-option", {
                attrs: { label: "退货入库", value: "enter_warehouse_refund" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.reductjson.erp_v2_enter_warehouse == 1
            ? _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择审批人",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.reviewAduitId,
                    callback: function ($$v) {
                      _vm.reviewAduitId = $$v
                    },
                    expression: "reviewAduitId",
                  },
                },
                _vm._l(_vm.reductjson.adminData, function (item) {
                  return _c("el-option", {
                    key: item.merchant_admin_id,
                    attrs: {
                      label: item.real_name,
                      value: item.merchant_admin_id,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: {
                data: _vm.enterGoodsList,
                size: "small",
                "highlight-current-row": "",
                border: "",
              },
              on: { "header-dragend": _vm.changeColumnWidth },
            },
            [
              _vm._l(_vm.pushheaderList, function (item, index) {
                return [
                  item.field == "image"
                    ? [
                        _c("el-table-column", {
                          attrs: {
                            label: item.name,
                            width: item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "demo-image__preview",
                                        on: {
                                          click: function ($event) {
                                            return _vm.modalPicTap(
                                              "2",
                                              "",
                                              "",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        scope.row.slider_image,
                                        function (it, index) {
                                          return it
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "30px",
                                                  height: "30px",
                                                },
                                                attrs: { src: it + "!120a" },
                                              })
                                            : _vm._e()
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "product_no"
                    ? [
                        _c("el-table-column", {
                          attrs: {
                            label: item.name,
                            prop: "",
                            minWidth: item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          staticClass: "sortCol",
                                          on: {
                                            focus: function ($event) {
                                              return _vm.focus($event)
                                            },
                                          },
                                          model: {
                                            value: scope.row.product_no,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "product_no",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.product_no",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "product_name"
                    ? [
                        _c("el-table-column", {
                          attrs: {
                            label: item.name,
                            prop: "",
                            minWidth: item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(scope.row.product_name) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "label"
                    ? [
                        _c("el-table-column", {
                          attrs: {
                            label: item.name,
                            minWidth: "200",
                            prop: "label",
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        ref: "label",
                                        refInFor: true,
                                        staticClass: "sortCol",
                                        attrs: {
                                          filterable: "",
                                          placeholder: "请选择",
                                          multiple: "",
                                          "collapse-tags": "",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.focus($event)
                                          },
                                        },
                                        model: {
                                          value: scope.row.label_ids,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "label_ids",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.label_ids",
                                        },
                                      },
                                      _vm._l(_vm.labelarr, function (item) {
                                        return _c("el-option", {
                                          key: item.label_id,
                                          attrs: {
                                            label: item.label_name,
                                            value: item.label_id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "shelf_id"
                    ? [
                        _c("el-table-column", {
                          attrs: {
                            label: "选择货架（选填）",
                            minWidth: item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "sortCol",
                                        attrs: {
                                          disabled:
                                            !_vm.formValidate.warehouse_id,
                                          placeholder: "请选择货架",
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.focus($event)
                                          },
                                        },
                                        model: {
                                          value: scope.row.shelf_id,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "shelf_id", $$v)
                                          },
                                          expression: "scope.row.shelf_id",
                                        },
                                      },
                                      _vm._l(_vm.shelfList, function (item) {
                                        return _c("el-option", {
                                          key: item.shelf_id,
                                          attrs: {
                                            label: item.shelf_no,
                                            value: item.shelf_id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.newtype == "attribute"
                    ? [
                        _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.name,
                            minWidth: item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      staticClass: "sortCol",
                                      attrs: {
                                        placeholder: "",
                                        size: "normal",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.focus($event)
                                        },
                                      },
                                      model: {
                                        value: scope.row.attribute[item.field],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row.attribute,
                                            item.field,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.attribute[item.field]",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delGoods(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            移除\n          ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "w300 mt20",
            attrs: { rows: 3, type: "textarea", placeholder: "请输入备注" },
            model: {
              value: _vm.formValidate.remark,
              callback: function ($$v) {
                _vm.$set(_vm.formValidate, "remark", $$v)
              },
              expression: "formValidate.remark",
            },
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        确认" +
                          _vm._s(_vm.id ? "修改" : "创建") +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, offset: 0 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "font-size": "20px", "font-weight": "500" } },
            [_vm._v("\n    从委托标的中选择\n  ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "pushTabelRef",
                  staticClass: "table",
                  attrs: {
                    data: _vm.tableData.data,
                    size: "small",
                    "highlight-current-row": "",
                    width: "100%",
                    border: "",
                  },
                },
                [
                  _c("ytx-table-column", {
                    attrs: {
                      label: "委托方",
                      width: "120",
                      prop: "",
                      "search-key": "entrust_keyword",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.entrustUser
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.entrustUser.real_name) +
                                      "(" +
                                      _vm._s(scope.row.entrustUser.phone) +
                                      ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "货品名称",
                      width: "120",
                      prop: "",
                      "search-key": "product_name",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.product_name) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.attributeList, function (item, index) {
                    return [
                      _c("ytx-table-column", {
                        key: "ar" + index,
                        attrs: {
                          label: item.name,
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row.attribute[item.field]) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "合同编号",
                      "min-width": "140",
                      prop: "",
                      "search-key": "contract_keyword",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.contract.contract_no) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "业务员",
                      "min-width": "140",
                      prop: "",
                      "search-key": "salesman_admin_id",
                      "search-type": "radio",
                      options: _vm.userList,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.contract &&
                            scope.row.contract.salesmanAdmin
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.contract.salesmanAdmin.real_name
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "备注",
                      prop: "",
                      "min-width": "140",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(" " + _vm._s(scope.row.remark) + " "),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "签约时间",
                      prop: "",
                      "min-width": "150",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(" " + _vm._s(scope.row.create_time) + " "),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "操作",
                      width: "60",
                      fixed: "right",
                      align: "center",
                      "search-clear": true,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "font-size": "14px" },
                                attrs: { type: "text", size: "small" },
                                on: { click: _vm.saleGoodsAll },
                              },
                              [_vm._v(" 全选 ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saleGoods(
                                          scope.row,
                                          "goods",
                                          scope.row.entrust_uid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.goodsIdArr.indexOf(scope.row.id) >
                                            -1
                                            ? "已选择"
                                            : "选择"
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block paginaBox" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 40, 60, 80],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, prev, pager, next, sizes",
                      total: _vm.tableData.total,
                      background: "",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
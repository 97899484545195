"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.getConfigClassKeys)('auction_rule_set').then(function (res) {
      if (res.data && (res.data.order_is_valuation || res.data.order_is_valuation === 0)) {
        if (res.data.auction_picture_show === '') {
          res.data.auction_picture_show = '1';
        }
        if (res.data.price_format) res.data.price_format = Number(res.data.price_format);
        if (res.data.compel_anonymous) res.data.compel_anonymous = Number(res.data.compel_anonymous);
        if (res.data.nickname_anonymous_type) res.data.nickname_anonymous_type = Number(res.data.nickname_anonymous_type);
        if (res.data.avatar_anonymous_type) res.data.avatar_anonymous_type = Number(res.data.avatar_anonymous_type);
        if (res.data.bid_confirm) res.data.bid_confirm = Number(res.data.bid_confirm);
        if (res.data.negotiate_purchase_switch) res.data.negotiate_purchase_switch = Number(res.data.negotiate_purchase_switch);
        if (res.data.confirm_network_bidding) res.data.confirm_network_bidding = Number(res.data.confirm_network_bidding);
        if (res.data.finish_confirm_number) res.data.finish_confirm_number = Number(res.data.finish_confirm_number);
        if (res.data.finish_show_transaction_price) res.data.finish_show_transaction_price = Number(res.data.finish_show_transaction_price);
        if (res.data.confirm_start_price) res.data.confirm_start_price = Number(res.data.confirm_start_price);
        if (res.data.order_signing_agreement) res.data.order_signing_agreement = Number(res.data.order_signing_agreement);
        if (res.data.liveEndNotShowAlbumData) res.data.liveEndNotShowAlbumData = Number(res.data.liveEndNotShowAlbumData);
        if (res.data.check_agreement === '') {
          res.data.check_agreement = '0';
        }
        if (res.data.auction_image_show_message === '') {
          res.data.auction_image_show_message = 1;
        }
        if (res.data.auction_refund_entrust_deliver_sign === '') {
          res.data.auction_refund_entrust_deliver_sign = 0;
        }
        if (res.data.settlementRatio === '') {
          res.data.settlementRatio = 0;
        }
        _this.ruleData = res.data;
      } else {
        _this.ruleData = {
          auction_examine: '',
          avatar_anonymous_type: 2,
          bid_confirm: 0,
          auction_picture_show: '1',
          dialog_title: '',
          compel_anonymous: 1,
          confirm_network_bidding: 0,
          confirm_start_price: 1,
          finish_show_transaction_price: 0,
          delivery_description: '',
          finish_confirm_number: 1,
          invoice_description: '',
          invoice_reminder: 0,
          is_open_invoice: 1,
          negotiate_purchase_switch: 0,
          nickname_anonymous_type: 3,
          order_auto_confirm_receive_day: 0,
          order_is_valuation: 2,
          order_refund: 0,
          order_signing_agreement: 1,
          order_timeout: 0,
          pay_tail_max_use_point: 0,
          pay_tail_use_point: 0,
          price_format: 1,
          product_order_auto_confirm_receive_day: 0,
          product_order_refund: 0,
          product_order_timeout: 0,
          refund_address_info: '',
          refund_address_name: '',
          refund_address_phone: '',
          reset_time: 0,
          transfer_album_id: '',
          transfer_live_id: '',
          transfer_video_costom_id: '',
          trigger_reset_time: 0,
          auction_image_show_message: 1,
          auction_refund_entrust_deliver_sign: 0,
          settlementRatio: 0
        };
      }
    }).catch(function () {});
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      // if (Number(this.ruleData.order_auto_confirm_receive_day)) {
      //   if (
      //     Number(this.ruleData.order_auto_confirm_receive_day) < 7 ||
      //     Number(this.ruleData.order_auto_confirm_receive_day) > 30
      //   ) {
      //     this.$message.error('自动收货时间只能设置7-30天之间');
      //     return false;
      //   }
      // } else {
      //   this.ruleData.order_auto_confirm_receive_day = 0;
      // }
      if (!this.ruleData.order_auto_confirm_receive_day) this.ruleData.order_auto_confirm_receive_day = 0;
      if (!this.ruleData.product_order_refund) this.ruleData.product_order_refund = 0;
      if (!this.ruleData.order_refund) this.ruleData.order_refund = 0;
      if (!this.ruleData.bid_confirm) this.ruleData.bid_confirm = 0;
      if (!this.ruleData.finish_show_transaction_price) this.ruleData.finish_show_transaction_price = 0;
      if (!this.ruleData.product_order_timeout) this.ruleData.product_order_timeout = 0;
      (0, _system.configClassKeysSave)('auction_rule_set', this.ruleData).then(function (res) {
        _this2.$message.success('设置成功');
      }).catch(function () {});
    },
    compelAnonymousAction: function compelAnonymousAction(val) {
      if (val === 1 && this.ruleData.nickname_anonymous_type < 3) {
        this.ruleData.nickname_anonymous_type = 3;
      }
      if (val === 1 && this.ruleData.avatar_anonymous_type !== 2) {
        this.ruleData.avatar_anonymous_type = 2;
      }
    }
  }
};
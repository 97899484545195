"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _auction = require("@/api/auction");
var _user = require("@/api/user");
var _setting = require("@/api/setting");
var _system = require("@/api/system");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      venue_id: '',
      status: '1',
      list: null,
      listLoading: true,
      queryParams: {
        page: 1,
        limit: 20
      },
      total: 0,
      searchQuery: {},
      row: {},
      uid: '',
      showdetail: false,
      albumlist: [],
      visible: false,
      rowID: '',
      manage_admin_id: '',
      adminarr: [],
      butLoad: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.venue_id = this.$route.query.vid || '';
    (0, _auction.albumOptionList)({
      page: 1,
      limit: 100,
      venue_id: this.venue_id
    }).then(function (res) {
      _this.albumlist = res.data.list.map(function (item) {
        return {
          label: "".concat(item.album_name, " ").concat(item.auction_num, "\u4EF6 \uFF08LOT").concat(item.LotArea.min, "-").concat(item.LotArea.max, "\uFF09"),
          value: item.album_id
        };
      });
    });
    this.load();
  },
  methods: {
    onAdd: function onAdd() {
      this.$router.push({
        name: 'entrustDetail',
        query: {
          venue_id: this.venue_id
        }
      });
    },
    onUpd: function onUpd(row) {
      this.$router.push({
        name: 'entrustDetail',
        query: {
          venue_id: this.venue_id,
          id: row.id
        }
      });
    },
    onDistri: function onDistri(row) {
      var _this2 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this2.adminarr = res.data.list;
      });
      this.rowID = row.id;
      this.manage_admin_id = '';
      this.visible = true;
    },
    onSubmit: function onSubmit() {
      var _this3 = this;
      this.butLoad = true;
      (0, _auction.assignAdminApi)(this.rowID, {
        manage_admin_id: this.manage_admin_id
      }).then(function (res) {
        _this3.butLoad = false;
        _this3.load();
        _this3.$message.success('操作成功');
        _this3.visible = false;
      }).catch(function (err) {
        return _this3.butLoad = false;
      });
    },
    statusChange: function statusChange() {
      this.searchQuery = {};
      this.load();
    },
    changeSortAction: function changeSortAction(_ref) {
      var column = _ref.column,
        prop = _ref.prop,
        order = _ref.order;
      // console.log(prop, order);
      var obj = {
        ascending: {
          lot: 'lot_asc',
          proxyPrice: 'proxy_price_asc',
          nowPrice: 'now_price_asc',
          createTime: 'create_time_asc'
        },
        descending: {
          lot: 'lot_desc',
          proxyPrice: 'proxy_price_desc',
          nowPrice: 'now_price_desc',
          createTime: 'create_time_desc'
        }
      };
      this.queryParams.order_by = obj[order][prop];
      this.load();
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.queryParams = Object.assign({
        page: 1,
        limit: this.queryParams.limit
      }, e);
      this.load();
    },
    load: (0, _index.debounce)(function () {
      var _this4 = this;
      this.listLoading = true;
      var acutionUrl;
      if (this.status == '0') acutionUrl = _auction.phoneEntrustListApi;
      if (this.status == '1') acutionUrl = _auction.proxyBidListApi;
      acutionUrl((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.queryParams), {}, {
        venue_id: this.venue_id
      })).then(function (response) {
        _this4.total = response.data.count;
        _this4.list = response.data.list;
        if (_this4.status == '0') {
          _this4.$nextTick(function () {
            if (_this4.$refs.phoneRef) _this4.$refs.phoneRef.doLayout();
          });
        }
        // if (this.status == '1') {
        // 	this.list = response.data;
        // }
        _this4.listLoading = false;
      });
    }, 300),
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.limit = val;
      this.load();
    },
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    delAlbum: function delAlbum(id) {
      var _this5 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.entrustDeleteApi)(id).then(function () {
          _this5.$message.success('删除成功');
          _this5.load();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    exportOrder: function exportOrder() {
      var _this6 = this;
      this.queryParams.export_type = 'pmAuctionPhoneEntrust';
      (0, _system.exportexcel)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.queryParams), {}, {
        venue_id: this.venue_id
      })).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this6.$createElement;
        _this6.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this6.$msgbox.close();
                _this6.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('pmAuctionPhoneEntrust');
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { clearable: "", filterable: "" },
          on: { change: _vm.changeAuctionId },
          model: {
            value: _vm.lastAuctionId,
            callback: function ($$v) {
              _vm.lastAuctionId = $$v
            },
            expression: "lastAuctionId",
          },
        },
        _vm._l(_vm.lastAuction, function (item) {
          return _c("el-option", {
            key: item.venue_id,
            attrs: { label: item.title, value: item.venue_id },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 240px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "letterRef",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                height: "100%",
                border: "",
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                key: "1",
                attrs: { type: "selection", width: "30" },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托方",
                  width: "160",
                  prop: "",
                  "search-key": "entrust_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(
                                          scope.row.entrustUser
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(scope.row.entrustUser.nickname) +
                                        "\n            "
                                    ),
                                    scope.row.entrustUser.real_name
                                      ? [
                                          _vm._v(
                                            "\n              (" +
                                              _vm._s(
                                                scope.row.entrustUser.real_name
                                              ) +
                                              ")\n            "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                scope.row.entrustUser.mark
                                  ? [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(scope.row.entrustUser.mark) +
                                          "\n          "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "图录号",
                  width: "80",
                  prop: "",
                  "search-key": "lot",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAuction
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.pmAuction.lot) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "合同编号",
                  width: "150",
                  prop: "",
                  "search-key": "contract_no",
                  "show-overflow-tooltip": "",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contract
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.contract.contract_no) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍品名称",
                  width: "130",
                  prop: "",
                  "search-key": "auction_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAuction && scope.row.pmAuction.product
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.pmAuction.product.store_name
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "专场",
                  width: "130",
                  prop: "",
                  "search-key": "album_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pmAlbum
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.pmAlbum.album_name) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "销售状态",
                  width: "90",
                  prop: "",
                  "search-key": "auction_status",
                  "search-type": "radio",
                  options: [
                    { label: "交割失败", value: "-1" },
                    { label: "流拍", value: "4" },
                    { label: "成交", value: "5" },
                    { label: "私洽成交", value: "7" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.auctionStatusText) +
                              "\n        "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "订单状态",
                  width: "90",
                  prop: "",
                  "search-key": "store_order_status",
                  "search-type": "radio",
                  options: [
                    { label: "待支付", value: "0" },
                    { label: "已结算", value: "1" },
                    { label: "已违约", value: "-2" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.storeOrderStatusText) +
                              "\n        "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "对账人",
                  width: "90",
                  prop: "",
                  "search-key": "audit_admin_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditAdmin
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.auditAdmin.real_name) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "对账状态",
                  width: "90",
                  prop: "",
                  "search-key": "audit_status",
                  "search-type": "radio",
                  options: [
                    { label: "待对账", value: "0" },
                    { label: "已对账", value: "1" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "nowrap" },
                          [
                            scope.row.audit_status === 0
                              ? void 0
                              : scope.row.audit_status == 1
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getcheckOrder(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              已对账\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "复核人",
                  width: "90",
                  prop: "",
                  "search-key": "audit_two_admin_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditTwoAdmin
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.auditTwoAdmin.real_name) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "复核状态",
                  width: "90",
                  prop: "",
                  "search-key": "audit_two_status",
                  "search-type": "radio",
                  options: [
                    { label: "待对账", value: "0" },
                    { label: "已对账", value: "1" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "nowrap" },
                          [
                            scope.row.audit_two_status === 0
                              ? void 0
                              : scope.row.audit_two_status == 1
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getcheckOrder(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              已对账\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "退回状态",
                  width: "90",
                  prop: "",
                  "search-key": "",
                  "search-type": "radio",
                  options: [{ label: "全部", value: "0" }],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.erpProduct
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                scope.row.erpProduct.product_warehouse_status ==
                                3
                                  ? [_vm._v("待出库")]
                                  : scope.row.erpProduct
                                      .product_warehouse_status == 4
                                  ? [_vm._v("已出库")]
                                  : [_vm._v("-")],
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "余额变动",
                  width: "90",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "nowrap",
                            staticStyle: { color: "red" },
                          },
                          [
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.settlement_amount > 0 ? "+" : ""
                                  ) +
                                  "\n            " +
                                  _vm._s(scope.row.settlement_amount)
                              ),
                            ]),
                            _vm._v(" "),
                            Number(scope.row.extra_settlement_amount) != 0
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.extra_settlement_amount > 0
                                          ? "+"
                                          : ""
                                      ) +
                                      "\n            " +
                                      _vm._s(
                                        scope.row.extra_settlement_amount
                                      ) +
                                      "\n          "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "保留价",
                  width: "90",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.erpProduct.retain_price) +
                              "\n        "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "对账编号",
                  width: "130",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "check_order_no",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustCheckOrder
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.entrustCheckOrder.order_no) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "付款单号",
                  width: "130",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "check_order_no",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.walletExtract
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.walletExtract.order_no) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "业务员",
                  width: "90",
                  prop: "",
                  "search-key": "salesman_admin_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.salesmanAdmin
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.salesmanAdmin.real_name) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "备注",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.remark) +
                              "\n        "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  prop: "",
                  "search-query": _vm.searchQuery,
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("i", { staticClass: "el-icon-printer" })]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.orderPrintRecord &&
                        scope.row.orderPrintRecord.create_time
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    scope.row.orderPrintRecord.create_time,
                                  placement: "top-end",
                                  effect: "light",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-document-checked cur",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.audit_status == 0
                          ? _c(
                              "TableRightBtn",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onReconc(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "class-name": "blue",
                                        "icon-class": "commonprint",
                                      },
                                    }),
                                    _vm._v("对账\n          "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c goodslist-page-box" },
        [
          _c(
            "div",
            {
              staticClass: "flex-one flex",
              staticStyle: { "margin-left": "4px" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onPayment },
                },
                [_vm._v("创建付款单")]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportOrder },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.getExportFileList },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.checkmore },
                },
                [_vm._v("批量对账")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.printAllAction("pay")
                    },
                  },
                },
                [_vm._v("打印对账单")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-right": "10px" },
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes ",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("SettlementPrint", { ref: "printView", on: { load: _vm.getList } }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showcheck, width: "1100px" },
          on: {
            "update:visible": function ($event) {
              _vm.showcheck = $event
            },
          },
        },
        [
          _c("div", { staticClass: "prop-title" }, [
            _vm._v(_vm._s(_vm.venueName)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "prop-name" }, [
            _vm._v(
              "委托方" +
                _vm._s(_vm.checkoneJson.entrustUser.real_name) +
                "对账单"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "prop-list" }, [
            _c("div", { staticClass: "prop-list-c" }, [
              _vm._v(
                "\n        对账人：" +
                  _vm._s(
                    _vm.checkoneJson.auditAdmin
                      ? _vm.checkoneJson.auditAdmin.real_name
                      : ""
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "prop-list-c" }, [
              _vm._v(
                "\n        复核人：" +
                  _vm._s(
                    _vm.checkoneJson.auditTwoAdmin
                      ? _vm.checkoneJson.auditTwoAdmin.real_name
                      : ""
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "prop-list-c" }, [
              _vm._v("单据编号：" + _vm._s(_vm.checkoneJson.order_no || "")),
            ]),
            _vm._v(" "),
            _vm.checkoneJson.createAdmin
              ? _c("div", { staticClass: "flex-one prop-list-r" }, [
                  _vm._v(
                    "\n        制单人：" +
                      _vm._s(_vm.checkoneJson.createAdmin.real_name) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "diaTabel" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    data: _vm.checkoneJson.check_auction_data,
                  },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "LOT", width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.auction.lot) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "拍品名称", width: "140" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.auction.product
                              ? [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        scope.row.auction.product.store_name
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "销售状态", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.auctionStatusText) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "落锤价", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.auction.now_price) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "买家结算", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " + _vm._s(scope.row.storeOrderStatusText) + " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "保留价", width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(_vm._s(scope.row.auction.retain_price)),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "服务费用明细", "min-width": "200" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.price_data
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex",
                                      staticStyle: {
                                        "flex-direction": "column",
                                        margin: "10px 0",
                                      },
                                    },
                                    [
                                      scope.row.auction_status <= 4
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                width: "100%",
                                                "justify-content":
                                                  "space-around",
                                              },
                                            },
                                            _vm._l(
                                              scope.row.price_data[1],
                                              function (e, ind) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "1" + ind,
                                                    staticStyle: {
                                                      "line-height": "20px",
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                    },
                                                  },
                                                  [
                                                    e.name &&
                                                    e.deduction_amount != 0
                                                      ? [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(e.name) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : e.number
                                                                ) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : "%"
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          e.deduction_amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "-" +
                                                                    _vm._s(
                                                                      e.deduction_amount
                                                                    ) +
                                                                    "元"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                width: "100%",
                                                "justify-content":
                                                  "space-around",
                                              },
                                            },
                                            _vm._l(
                                              scope.row.price_data[0],
                                              function (e, ind) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "0" + ind,
                                                    staticStyle: {
                                                      "line-height": "20px",
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                    },
                                                  },
                                                  [
                                                    e.name &&
                                                    e.deduction_amount != 0
                                                      ? [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(e.name) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : e.number
                                                                ) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : "%"
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          e.deduction_amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "-" +
                                                                    _vm._s(
                                                                      e.deduction_amount
                                                                    ) +
                                                                    "元"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            width: "100%",
                                            "justify-content": "space-around",
                                          },
                                        },
                                        _vm._l(
                                          scope.row.price_data[2],
                                          function (e, ind) {
                                            return _c(
                                              "div",
                                              {
                                                key: "2" + ind,
                                                staticStyle: {
                                                  "line-height": "20px",
                                                  display: "flex",
                                                  "flex-direction": "column",
                                                },
                                              },
                                              [
                                                e.name &&
                                                e.deduction_amount != 0
                                                  ? [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(e.name) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : e.number
                                                            ) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : "%"
                                                            )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      e.deduction_amount
                                                        ? _c("div", [
                                                            _vm._v(
                                                              "-" +
                                                                _vm._s(
                                                                  e.deduction_amount
                                                                ) +
                                                                "元"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "结算金额", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.settlement_amount) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-end",
                "font-weight": "bold",
                padding: "10px 0",
                "border-bottom": "1px solid #f4f6fd",
                "border-left": "1px solid #f4f6fd",
                "border-right": "1px solid #f4f6fd",
                "margin-bottom": "20px",
              },
            },
            [
              _c("div", { staticStyle: { width: "480px" } }),
              _vm._v(" "),
              _vm.checkoneJson.configPrice
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "1",
                        "padding-left": "10px",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _vm.checkoneJson.obj.err > 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                width: "100%",
                                "justify-content": "space-around",
                              },
                            },
                            _vm._l(
                              _vm.checkoneJson.configPrice[1],
                              function (e, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: "1" + ind,
                                    staticStyle: {
                                      "line-height": "20px",
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    e.name && e.deduction_amount != 0
                                      ? [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(e.name) +
                                                _vm._s(
                                                  e.unit == 1 ? "" : e.number
                                                ) +
                                                _vm._s(e.unit == 1 ? "" : "%")
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          e.deduction_amount
                                            ? _c("div", [
                                                _vm._v(
                                                  "-" +
                                                    _vm._s(e.deduction_amount) +
                                                    "元"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.checkoneJson.obj.succ > 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                width: "100%",
                                "justify-content": "space-around",
                              },
                            },
                            _vm._l(
                              _vm.checkoneJson.configPrice[0],
                              function (e, ind) {
                                return _c(
                                  "div",
                                  {
                                    key: "0" + ind,
                                    staticStyle: {
                                      "line-height": "20px",
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  [
                                    e.name && e.deduction_amount != 0
                                      ? [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(e.name) +
                                                _vm._s(
                                                  e.unit == 1 ? "" : e.number
                                                ) +
                                                _vm._s(e.unit == 1 ? "" : "%")
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          e.deduction_amount
                                            ? _c("div", [
                                                _vm._v(
                                                  "-" +
                                                    _vm._s(e.deduction_amount) +
                                                    "元"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            width: "100%",
                            "justify-content": "space-around",
                          },
                        },
                        _vm._l(
                          _vm.checkoneJson.configPrice[2],
                          function (e, ind) {
                            return _c(
                              "div",
                              {
                                key: "2" + ind,
                                staticStyle: {
                                  "line-height": "20px",
                                  display: "flex",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                e.name && e.deduction_amount != 0
                                  ? [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(e.name) +
                                            _vm._s(
                                              e.unit == 1 ? "" : e.number
                                            ) +
                                            _vm._s(e.unit == 1 ? "" : "%")
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      e.deduction_amount
                                        ? _c("div", [
                                            _vm._v(
                                              "-" +
                                                _vm._s(e.deduction_amount) +
                                                "元"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "100px", "padding-left": "5px" } },
                [_vm._v(_vm._s(_vm.checkoneJson.account))]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "check-bottom" }, [
            _c(
              "div",
              { staticClass: "check-bottom-left" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "check-bottom-left-type",
                    attrs: { disabled: !_vm.isNo },
                    on: { change: _vm.countmoney },
                    model: {
                      value: _vm.proptype,
                      callback: function ($$v) {
                        _vm.proptype = $$v
                      },
                      expression: "proptype",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "增加(元)", value: "1" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "减少(元)", value: "2" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "check-bottom-left-money",
                  attrs: { disabled: !_vm.isNo },
                  on: { change: _vm.countmoney, input: _vm.countmoney },
                  model: {
                    value: _vm.propmoney,
                    callback: function ($$v) {
                      _vm.propmoney = $$v
                    },
                    expression: "propmoney",
                  },
                }),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { placeholder: "请输入备注", disabled: !_vm.isNo },
                  model: {
                    value: _vm.propmark,
                    callback: function ($$v) {
                      _vm.propmark = $$v
                    },
                    expression: "propmark",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "check-bottom-right" }, [
              _vm._v("\n        最终合计:\n        "),
              _c("span", { staticClass: "check-bottom-right-money" }, [
                _vm._v(_vm._s(_vm.checkoneJson.settlement_amount)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.isNo
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.cursorCheck },
                },
                [_vm._v("确认对账")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "exchangeRate",
  components: {},
  props: {},
  data: function data() {
    return {
      consfigOption: {},
      checkList: [],
      currencyList: [{
        type: "USD",
        icon: "https://saas.cdn.yunzongbu.cn/merchant/23/20241010/image/1728526916017708731.png!120a",
        rate: ""
      }, {
        type: "EUR",
        icon: "https://saas.cdn.yunzongbu.cn/merchant/23/20241010/image/1728526916019315741.png!120a",
        rate: ""
      }, {
        type: "JPY",
        icon: "https://saas.cdn.yunzongbu.cn/merchant/23/20241010/image/1728526916019664296.png!120a",
        rate: ""
      }, {
        type: "GBP",
        icon: "https://saas.cdn.yunzongbu.cn/merchant/23/20241010/image/1728526916021764857.png!120a",
        rate: ""
      }, {
        type: "HKD",
        icon: "https://saas.cdn.yunzongbu.cn/merchant/23/20241010/image/1728526916020779231.png!120a",
        rate: ""
      }, {
        type: "TWD",
        icon: "https://saas.cdn.yunzongbu.cn/merchant/23/20241010/image/1728526916019887897.png!120a",
        rate: ""
      }, {
        type: "CAD",
        icon: "https://saas.cdn.yunzongbu.cn/merchant/23/20241010/image/1728526916020379388.png!120a",
        rate: ""
      }, {
        type: "AUD",
        icon: "https://saas.cdn.yunzongbu.cn/merchant/23/20241010/image/1728526916020342076.png!120a",
        rate: ""
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      var arr = [];
      (0, _setting.getExchangeRateConfigListApi)('1').then(function (res) {
        _this.consfigOption = res.data.feeType;
        _this.currencyList.forEach(function (item, index) {
          res.data.exchangeRateData.forEach(function (ele, ind) {
            if (item.type == ele.type) {
              if (ele.is_choice == 1) {
                arr.push(ele.type);
                if (ele.set_rate) {
                  item.rate = ele.set_rate;
                  item.isno = true;
                } else item.rate = ele.rate;
              } else item.rate = ele.rate;
              item.name = ele.name;
              item.set_rate = ele.set_rate;
            }
          });
        });
        _this.checkList = arr;
        _this.$forceUpdate();
        console.log(_this.currencyList);
      });
    },
    onInput: function onInput(row) {
      row.set_rate = Number(row.rate);
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      var model = {
        exchange_rate: {}
      };
      this.currencyList.forEach(function (item, index) {
        // if (this.checkList.includes(item.type)) {
        if (item.set_rate) {
          model.exchange_rate[item.type] = item.set_rate;
        }
      });
      (0, _setting.setExchangeRateConfig)(model).then(function (res) {
        _this2.init();
        _this2.$message.success("\u4FDD\u5B58\u6210\u529F");
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // 注册draggable组件
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default
  },
  props: {
    value: ''
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    pageInfo: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    uploadIconAction: function uploadIconAction(res, file, key, key1) {
      if (res && res.url) {
        var newFile = file;
        newFile.url = res.url;
        if (key1) {
          this.pageInfo[key1][key] = res.url;
        } else {
          this.pageInfo[key] = res.url;
        }
      }
    },
    delImg: function delImg(key, key1) {
      if (key1) {
        this.pageInfo[key1][key] = '';
      } else {
        this.pageInfo[key] = '';
      }
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, key) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (key === 'logo') {
          _this.pageInfo.headerSetting.logo = img[0];
        } else if (key === 'logo_slide') {
          _this.pageInfo.headerSetting.logo_slide = img[0];
        }
      }, tit);
    }
  }
};
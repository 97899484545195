var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warehouse" },
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 170px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.id
                },
                data: _vm.tableData.data,
                height: "100%",
                "expand-row-keys": _vm.rowkeys,
                size: "mini",
                border: "",
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "expand-change": _vm.exchangeExpand,
                "cell-click": _vm.cellClick,
                "header-dragend": _vm.changeColumnWidth,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "36",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand", width: "36" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm.showsearch && props.$index == 0 ? void 0 : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "expand-table-box" },
                          [
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  data: props.row.expandData,
                                  size: "small",
                                  "highlight-current-row": "",
                                },
                              },
                              [
                                _c("template", { slot: "empty" }, [
                                  props.row.loading
                                    ? _c("div", [_vm._v("加载中...")])
                                    : _c("div", { staticClass: "tableEmpty" }, [
                                        _vm.$store.getters.userPression
                                          .merchantErpV2WarehouseOrderProductList
                                          ? _c(
                                              "div",
                                              { staticClass: "tableEmpty" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024619/image/171879035028190618.png!120a",
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "tableDiv" },
                                                  [_vm._v("暂无数据")]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "tableEmpty" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718954313071950674.png!120a",
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "tableDiv" },
                                                  [_vm._v("无权限查看")]
                                                ),
                                              ]
                                            ),
                                      ]),
                                ]),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "图片",
                                    width: "50",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "pictrue" },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "25px",
                                                    height: "25px",
                                                    "margin-top": "5px",
                                                  },
                                                  attrs: {
                                                    src: scope.row.erpProduct
                                                      .image,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "编号" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct
                                                    .product_no
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "LOT", width: "50" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct.lot
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "货品名称" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct
                                                    .product_name
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "标签" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct.labelNames.join(
                                                    ","
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "货架" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct.shelf
                                                    ? scope.row.erpProduct.shelf
                                                        .shelf_no
                                                    : ""
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm._l(_vm.outheaderList, function (item, index) {
                return [
                  item.field == "create_time"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          "min-width": "160",
                          prop: "",
                          "search-key": "date",
                          "search-type": "date",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row[item.field]) +
                                      "\n        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "out_time"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_time",
                          "search-type": "date",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row[item.field]) +
                                      "\n        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "entrustUser.nickname"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "entrust_keyword",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.entrustUser
                                            ? scope.row.entrustUser.nickname
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "entrustUser.real_name"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "entrust_real_name",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  item.field == "entrustUser.real_name"
                                    ? _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              scope.row.entrustUser
                                                ? scope.row.entrustUser
                                                    .real_name
                                                : ""
                                            ) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "entrustUser.phone"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "entrust_phone",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.entrustUser
                                            ? scope.row.entrustUser.phone
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "outStatusText"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_status",
                          "search-type": "radio",
                          options: [
                            { label: "已作废", value: "-1" },
                            { label: "待出库", value: "0" },
                            { label: "已出库", value: "1" },
                          ],
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(_vm._s(scope.row[item.field])),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "createAdmin.real_name"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "create_admin_id",
                          "search-type": "radio",
                          options: _vm.userList,
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.createAdmin
                                            ? scope.row.createAdmin.real_name
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "outWarehouse.name"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_id",
                          "search-type": "radio",
                          options: _vm.userList,
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.outWarehouse
                                            ? scope.row.outWarehouse.name
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "order_no"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "order_no",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(_vm._s(scope.row[item.field])),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "outTypeText"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_type",
                          "search-type": "radio",
                          options: [
                            { label: "销售出库", value: "out_warehouse_order" },
                            {
                              label: "退回出库",
                              value: "out_warehouse_refund",
                            },
                          ],
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row[item.field]) +
                                      "\n        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "salesmanAdmin.real_name"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "salesman_admin_keyword",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        scope.row.salesmanAdmin
                                          ? scope.row.salesmanAdmin.real_name
                                          : ""
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "outAdmin.real_name"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_admin_keyword",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        scope.row.outAdmin
                                          ? scope.row.outAdmin.real_name
                                          : ""
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "outModeText"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_mode",
                          "search-type": "radio",
                          options: [
                            { label: "快递发货", value: "express" },
                            { label: "自提", value: "self_take" },
                          ],
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(scope.row[item.field]) +
                                      "\n        "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "erp_product_number"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: "100",
                          prop: "",
                          "search-key": "erp_product_keyword",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(_vm._s(scope.row[item.field])),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  item.field == "outStatusText"
                                    ? _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(scope.row.outStatusText) +
                                            "\n            "
                                        ),
                                      ])
                                    : item.type == "outAdmin"
                                    ? _c("div", [
                                        scope.row.createAdmin
                                          ? _c(
                                              "div",
                                              { staticClass: "nowrap" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      scope.row.createAdmin
                                                        .real_name
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : item.type == "outModel"
                                    ? _c("div", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              scope.row.out_mode == "express"
                                                ? "快递发货"
                                                : "自提"
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : item.type == "createAdmin.real_name"
                                    ? _c("div", [
                                        scope.row.createAdmin
                                          ? _c(
                                              "div",
                                              { staticClass: "nowrap" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      scope.row.createAdmin
                                                        .real_name
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : item.type == "nickname"
                                    ? _c("div", [
                                        scope.row.express_user_name
                                          ? _c(
                                              "div",
                                              { staticClass: "nowrap" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      scope.row
                                                        .express_user_name
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : item.type == "express_user_phone"
                                    ? _c("div", [
                                        scope.row.express_user_phone
                                          ? _c(
                                              "div",
                                              { staticClass: "nowrap" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      scope.row
                                                        .express_user_phone
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(scope.row[item.field]) +
                                            "\n            "
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                ]
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  prop: "",
                  "search-query": _vm.searchQuery,
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("i", { staticClass: "el-icon-printer" })]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.orderPrintRecord &&
                        scope.row.orderPrintRecord.create_time
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    scope.row.orderPrintRecord.create_time,
                                  placement: "top-end",
                                  effect: "light",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-document-checked cur",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onSee(scope.row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "commonlook",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("查看详情"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.audit_status == 0 &&
                            _vm.merchant_admin_id == scope.row.audit_admin_id
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.revieworder(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 审批")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.out_status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onUpd(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "commonedit",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("编辑"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.out_status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value:
                                          "merchantErpV2WarehouseOrderConfirmOut",
                                        expression:
                                          "'merchantErpV2WarehouseOrderConfirmOut'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.outhouse(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "kufang-chuku",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("出库"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.out_status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.losewarehouseorder(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "class-name": "blue",
                                        "icon-class": "hetongzuofei ",
                                      },
                                    }),
                                    _vm._v("作废"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "merchantErpV2WarehouseOrderDetail",
                                    expression:
                                      "'merchantErpV2WarehouseOrderDetail'",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getdetail(scope.row.id)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "commonprint",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("打印出库单"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c goodslist-page-box" },
        [
          _c(
            "div",
            {
              staticClass: "flex-one flex",
              staticStyle: { "margin-left": "4px" },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantErpV2WarehouseOrderDetail",
                      expression: "'merchantErpV2WarehouseOrderDetail'",
                    },
                  ],
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-printer",
                  },
                  on: { click: _vm.getalldetail },
                },
                [_vm._v("打印出库单")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantSystemExcelExport:erpOutWarehouseOrder",
                      expression:
                        "'merchantSystemExcelExport:erpOutWarehouseOrder'",
                    },
                  ],
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出出库单")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantSystemExcelExport:erpOutWarehouseOrder",
                      expression:
                        "'merchantSystemExcelExport:erpOutWarehouseOrder'",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-document",
                  },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-right": "10px" },
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes ",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.urlshow
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": function () {
                _vm.urlshow = false
              },
              "url-list": _vm.urlshowList,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "打印标签",
            visible: _vm.showlabel,
            direction: "rtl",
            size: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showlabel = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("span", [_vm._v(" 打印标签 ")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.bindPrint },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "printTable" } },
            _vm._l(_vm.labelarr, function (item, index) {
              return _c(
                "div",
                { key: "label" + index, staticClass: "printbox" },
                [
                  _c("div", { staticClass: "print-image" }, [
                    _c("img", {
                      attrs: {
                        src: "data:image/png;base64," + item.bar_code_image,
                        alt: "",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "print-code" }, [
                    _vm._v(_vm._s(item.bar_code)),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "出库", visible: _vm.showouthouse, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showouthouse = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "100px",
                model: _vm.house,
                rules: _vm.rules,
              },
            },
            [
              _c("el-form-item", { attrs: { label: "凭证", prop: "image" } }, [
                _c(
                  "div",
                  { staticClass: "flex", staticStyle: { "flex-wrap": "wrap" } },
                  [
                    _vm._l(_vm.house.image, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "upLoadPicBox flex align-items-c flex-wrap",
                        },
                        [
                          _c("div", { staticClass: "pictrue mr10 mb10" }, [
                            _c("img", { attrs: { src: item + "!120a" } }),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "upLoadPicBox flex align-items-c flex-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap("1")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "upLoad mb10" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _vm.currentrow.out_mode == "express"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "物流公司" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择物流公司",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.house.delivery_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.house, "delivery_name", $$v)
                            },
                            expression: "house.delivery_name",
                          },
                        },
                        _vm._l(_vm.expressList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentrow.out_mode == "express"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "物流单号" } },
                    [
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: {
                          placeholder: "请输入物流单号",
                          size: "normal",
                          clearable: "",
                        },
                        model: {
                          value: _vm.house.delivery_no,
                          callback: function ($$v) {
                            _vm.$set(_vm.house, "delivery_no", $$v)
                          },
                          expression: "house.delivery_no",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticClass: "w200",
                    attrs: {
                      placeholder: "请输入备注",
                      size: "normal",
                      clearable: "",
                      type: "textarea",
                      rows: 3,
                    },
                    model: {
                      value: _vm.house.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.house, "remark", $$v)
                      },
                      expression: "house.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.cursorouthouse } }, [
                    _vm._v("确认出库"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showdetail, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.showdetail = $event
            },
          },
        },
        [
          _vm.showdetail
            ? _c("outwareOrder", { attrs: { detailjson: _vm.detailjson } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              !_vm.detailjson.type
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "default" },
                      on: { click: _vm.bindPrintorder },
                    },
                    [_vm._v("打印")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showimage, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showimage = $event
            },
          },
        },
        _vm._l(_vm.imagearr, function (item, index) {
          return _c("img", {
            key: "image" + index,
            staticStyle: { width: "200px" },
            attrs: { src: item, alt: "" },
          })
        }),
        0
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { rows: 3, type: "textarea" },
            model: {
              value: _vm.mask,
              callback: function ($$v) {
                _vm.mask = $$v
              },
              expression: "mask",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showreview = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(-1)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(1)
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
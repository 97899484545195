"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _auction = require("@/api/auction");
var _setting = require("@/api/setting");
var _request = _interopRequireDefault(require("@/api/request"));
var _ChangeMap = _interopRequireDefault(require("@/components/ChangeMap"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ChangeMap: _ChangeMap.default
  },
  data: function data() {
    var _this = this;
    return {
      formState: {
        time: '1',
        startTime: '1',
        address_long_lat: '',
        is_show: 1,
        bond_price: '',
        label: [],
        fee_type: ''
      },
      ruleValidate: {
        title: [{
          required: true,
          message: "请输入委托方名称",
          trigger: "blur"
        }],
        // time: [{ required: true, message: "请输入预展时间" } ],
        // startTime: [{ required: true, message: "请输入开拍时间" } ],
        address_name: [{
          required: true,
          message: "请输入拍卖地点",
          trigger: "blur"
        }]
      },
      timeObj: {},
      timeObjO: {},
      timeObjT: {},
      id: '',
      saveLock: false,
      venueType: this.$route.query.venue_type || '1',
      fApi: {},
      formData: {},
      option: {
        // 表单提交按扭事件
        onSubmit: function onSubmit(formData) {
          console.log('获取表单中的数据：', formData);

          // 按钮进入提交状态
          if (_this.saveLock) return false;
          _this.saveLock = true;
          _request.default[_this.formData.method.toLowerCase()](_this.formData.action.slice(5), formData).then(function (res) {
            _this.$message.success(res.msg || '提交成功');
            _this.saveLock = false;
            // this.fApi.btn.finish()
            _this.$router.push('/merchant/pageManagement/auction/meeting');
          }).catch(function (msg) {
            _this.$message.error(msg || '提交失败');
            _this.saveLock = false;
            // this.fApi.btn.finish();
          });
        }
      },
      rateList: [],
      defaultFeeType: {}
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      (0, _auction.meetingDetail)(this.$route.query.id).then(function (res) {
        _this2.setTime('timeObj', res.data.preview_start_time * 1000);
        _this2.setTime('timeObjO', res.data.preview_end_time * 1000);
        _this2.setTime('timeObjT', res.data.start_time * 1000);
        if (!res.data.label) res.data.label = [];else if (res.data.label) res.data.label = res.data.label.split(',');
        _this2.formState = res.data;
        _this2.formState.bond_price = Number(_this2.formState.bond_price);
      });
    } else {
      (0, _setting.getExchangeRateConfigListApi)('0').then(function (res) {
        _this2.rateList = res.data.exchangeRateData;
        _this2.defaultFeeType = res.data.feeType;
        _this2.formState.fee_type = res.data.feeType.type;
      });
      this.setTime('timeObj', new Date().getTime());
      this.setTime('timeObjO', new Date().getTime());
      this.setTime('timeObjT', new Date().getTime());
    }
    this.loadPage();
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this3 = this;
      var startTime = this.getTime('timeObj');
      var endTime = this.getTime('timeObjO');
      var KPTime = this.getTime('timeObjT');
      if (startTime == '' || endTime == '') {
        this.$message.error('请输入预展时间');
        return false;
      }
      if (KPTime == '') {
        this.$message.error('请输入开拍时间');
        return false;
      }
      this.formState.preview_time = [startTime, endTime];
      this.formState.start_time = KPTime;
      var data = JSON.parse(JSON.stringify(this.formState));
      data.label = this.formState.label.join(',');
      this.$refs['formRef'].validate(function (valid) {
        if (valid) {
          // console.log(this.formState);
          if (_this3.id) {
            (0, _auction.meetingUpdForm)(_this3.id, data).then(function (res) {
              _this3.$message.success('操作成功');
              _this3.backAction();
            });
          } else {
            (0, _auction.venueCreateApi)(data).then(function (res) {
              _this3.$message.success('操作成功');
              _this3.backAction();
            });
          }
        }
      });
    },
    modalPicTap: function modalPicTap(tit, num, i) {
      var that = this;
      this.$modalUpload(function (img) {
        that.formState.image = img[0];
        that.$forceUpdate();
      }, 1, '1', false, 2);
    },
    getTime: function getTime(key) {
      if (this[key].year == undefined || this[key].year == null || this[key].year == '' || this[key].month == undefined || this[key].month == null || this[key].month == '' || this[key].date == undefined || this[key].date == null || this[key].date == '' || (this[key].hour == undefined || this[key].hour == null || this[key].hour == '') && this[key].minute != 0 || (this[key].minute == undefined || this[key].minute == null || this[key].minute == '') && this[key].minute != 0) {
        return '';
      }
      var d = new Date("".concat(this[key].year, "/").concat(this[key].month, "/").concat(this[key].date));
      d.setHours(this[key].hour || 0);
      d.setMinutes(this[key].minute || 0);
      d.setSeconds(0);
      var time = Date.parse(d);
      return time / 1000;
      // return `${this[key].year}-${this[key].month<10?'0'+this[key].month:this[key].month}-${this[key].date<10?'0'+this[key].date:this[key].date} ${this[key].hour<10?'0'+this[key].hour:this[key].hour}:${this[key].minute<10?'0'+this[key].minute:this[key].minute}:00`
    },
    setTime: function setTime(key, date) {
      var d = new Date(date);
      this[key].year = d.getFullYear();
      this[key].month = d.getMonth() + 1;
      this[key].date = d.getDate();
      this[key].hour = d.getHours();
      this[key].minute = d.getMinutes();
    },
    loadPage: function loadPage() {
      var _this4 = this;
      var action = _auction.meetingCreateForm;
      if (this.id) {
        action = _auction.meetingEditForm;
      }
      action(this.id || '', {
        venue_type: this.venueType
      }).then(function (res) {
        // if (!res.data.label) res.data.label = [];
        _this4.formData = res.data;
      });
      // meetingCreateForm
      //   this.$modalForm(meetingCreateForm()).then(({ message }) => {
      //     // this.$message.success(message)
      //     // this.getList()
      //     console.log(1122);
      //   })
    },
    getAddress: function getAddress(data) {
      console.log(1, data);
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};